import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { Button, Table, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ReturnDDMMYYYYMarathi } from '../../features/auth/authSlice'

function GrahaBadalView({grahaRashiBadalData, StartEnable, EndEnable}) {

    const [startDate, setStartDate] = useState(null)
    const [convertedDate, setConverted] = useState(null)
  
    const [LoadingData, setLoadingData] = useState(null);

    useEffect(() => {
     if(startDate) setConverted(ReturnDDMMYYYYMarathi(startDate.toString()))
    }, [startDate])
  
    useEffect(() => {
      setStartDate(new Date())
    }, [])

    useEffect(() => {
      if(convertedDate && grahaRashiBadalData) {
        const newDailyMonthlyPanchang = grahaRashiBadalData.filter((item) => item.cmth === convertedDate.mahinaAnk && item.cyr === convertedDate.varsh)
        setLoadingData(newDailyMonthlyPanchang);
      }
    }, [convertedDate, grahaRashiBadalData])
  
    const PrevMonth = (dt) => {
      const newDate = new Date(dt);
      const month = newDate.getMonth() - 1;
      if (month === -1) {
        newDate.setFullYear(newDate.getFullYear() - 1);
        newDate.setMonth(11); // January
      } else {
        newDate.setMonth(month);
      }
    setStartDate(newDate)
  }
  
    const NextMonth = (dt) => {
        const newDate = new Date(dt);
        const month = newDate.getMonth() + 1;
        if (month === 12) {
          newDate.setFullYear(newDate.getFullYear() + 1);
          newDate.setMonth(0); // January
        } else {
          newDate.setMonth(month);
        }
      setStartDate(newDate)
    }

  return (
    <div id="appCapsule">
            <div className="section mt-2">
                <h3 className='text-center'>ग्रहराशी बदल</h3>
                <div>
                    {/* <div>
                        <Form.Select>
                            <option>सर्व</option>
                            <option>रवि</option>
                            <option>चंद्र</option>
                            <option>मंगळ</option>
                            <option>बुध</option>
                            <option>गुरु</option>
                            <option>शुक्र</option>
                            <option>शनि</option>
                            <option>प्लुटो</option>
                            <option>केतू</option>
                        </Form.Select>
                    </div> */}
                    <div className="my-2 d-flex justify-content-between align-items-center">
                        <Button variant="warning" size="sm" disabled={convertedDate?.year === StartEnable && convertedDate?.month === 0} className="dateArrows" onClick={() => PrevMonth(startDate)}>
                            <i className="bi bi-caret-left-fill"></i>
                        </Button>
                        <div className='position-relative '>
                            <label htmlFor='panchangId'>
                            <p className="text-center m-0 fw-bold text-secondary">{convertedDate?.mahina} {convertedDate?.varsh}</p>
                            </label>
                            <DatePicker minDate={new Date(`${StartEnable}/01/01`)}  maxDate={new Date(`${EndEnable}/12/31`)} showMonthYearPicker showFullMonthYearPicker dateFormatCalendar='MMM yyyy' id="panchangId" className='KGdate PanchangMonthPicker' selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                        <Button variant="warning" size="sm" disabled={convertedDate?.year === EndEnable && convertedDate?.month === 11} className="dateArrows" onClick={() => NextMonth(startDate)}>
                            <i className="bi bi-caret-right-fill"></i>
                        </Button>
                    </div>
                </div>
            </div>
            <div className='section mt-2'>
              <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>तारीख</th>
                        <th>मास</th>
                        <th>पक्ष</th>
                        <th>ग्रह</th>
                        <th>राशी</th>
                        <th>वेळ</th>
                    </tr>
                </thead>
                <tbody id="tdata">
                  {
                    LoadingData === null &&
                    <tr ng-if="panchangData.length == 0" className="ng-scope">
                        <td colspan="8" className="text-center">माहिती लोड करत आहे...</td>
                    </tr>
                  }
                  {
                    LoadingData && LoadingData.length === 0 &&
                    <tr ng-if="panchangData.length == 0" className="ng-scope">
                        <td colspan="8" className="text-center">माहिती उपलब्ध नाही</td>
                    </tr>
                  }
                  {
                    LoadingData && LoadingData.length > 0 &&
                    <>
                    {
                      LoadingData?.map((item, keyK) => {
                        return (
                            <tr key={keyK}>
                                <td className="text-center">{item.cdt}</td>
                                <td className="text-center">{item.mahina} {item.shake}</td>
                                <td className="text-center">{item.paksh}</td>
                                <td className="text-center">{item.graha}</td>
                                <td className="text-center">{item.rashi}</td>
                                <td className="text-center">{item.t4}</td>
                            </tr>
                        )
                      })
                    }
                    </>
                  }
                </tbody>
              </Table>
            </div>
        </div>
  )
}

const mapStateToProps = ({ auth }) => {
    const {
      otpRequestSuccess,
      grahaRashiBadalData,
      StartEnable,
      EndEnable
    } = auth
    return {
      otpRequestSuccess,
      grahaRashiBadalData,
      StartEnable,
      EndEnable
    }
  }
  
  const mapDispatchToProps = {
    ReturnDDMMYYYYMarathi
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(GrahaBadalView)