import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from '../../AxiosConfig'
import Loading from '../images/icon/loading-animate-allpos.svg'
import { showAppToast } from '../../features/utilities/appToastSlice'
import { setUserCallData } from '../../features/auth/authSlice'
import Logo from '../images/app_icon.png';

const Load = ({
  categoriesDataUpdate,
  itemsDataUpdate,
  setUserCallData
}) => {
  let navigate = useNavigate()
  const [callResponses, setCallResponses] = useState({
    agniData: null,
    grahaRashiBadalData: null,
    ahutiData: null,
    parjanyaNakshtraData: null,
    dhanishtaPanchakData: null,
    gurupushamrutYogData: null,
    amrutsidhiYogData: null,
    lopDarshanData: null,
    shubhDivasData: null,
    muhurtaData: null,
    dailyPanchang: null,
    upnayanData: null,
    lagnaData: null,
    sankashtiData: null,
    shaharData: null,
    muhurtaSubtitle: null,
    muhurtaType: null,
    panchangData: null,
    grahaneData: null,
  });

    useEffect(() => {
      // setTimeout(() => {

        axios.get('/application/agniData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data})
            setUserCallData({ agniData: res.data.payLoad})
            localStorage.setItem('agniData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              agniData: true
            }))
          } else {
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
            setCallResponses(prevState => ({
              ...prevState,
              agniData: false
            }))
          }
        })

        axios.get('/application/grahaRashiBadalData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data})
            setUserCallData({ grahaRashiBadalData: res.data.payLoad})
            localStorage.setItem('grahaRashiBadalData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              grahaRashiBadalData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              grahaRashiBadalData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/ahutiData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data})
            setUserCallData({ ahutiData: res.data.payLoad})
            localStorage.setItem('ahutiData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              ahutiData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              ahutiData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/parjanyaNakshtraData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data})
            setUserCallData({ parjanyaNakshtraData: res.data.payLoad})
            localStorage.setItem('parjanyaNakshtraData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              parjanyaNakshtraData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              parjanyaNakshtraData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/dhanishtaPanchakData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data})
            setUserCallData({ dhanishtaPanchakData: res.data.payLoad})
            localStorage.setItem('dhanishtaPanchakData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              dhanishtaPanchakData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              dhanishtaPanchakData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/gurupushamrutYogData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data})
            setUserCallData({ gurupushamrutYogData: res.data.payLoad})
            localStorage.setItem('gurupushamrutYogData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              gurupushamrutYogData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              gurupushamrutYogData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/amrutsidhiYogData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data})
            setUserCallData({ amrutsidhiYogData: res.data.payLoad})
            localStorage.setItem('amrutsidhiYogData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              amrutsidhiYogData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              amrutsidhiYogData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/lopDarshanData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data})
            setUserCallData({ lopDarshanData: res.data.payLoad})
            localStorage.setItem('lopDarshanData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              lopDarshanData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              lopDarshanData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/shubhDivasData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data})
            setUserCallData({ shubhDivasData: res.data.payLoad}) 
            localStorage.setItem('shubhDivasData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              shubhDivasData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              shubhDivasData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/muhurtaData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data}) 
            setUserCallData({ muhurtaData: res.data.payLoad}) 
            localStorage.setItem('muhurtaData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              muhurtaData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              muhurtaData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })



        axios.get('/application/dailyPanchang').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data}) 
            setUserCallData({ dailyPanchang: res.data.payLoad}) 
            localStorage.setItem('dailyPanchang', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              dailyPanchang: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              dailyPanchang: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/upnayanData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data}) 
            setUserCallData({ upnayanData: res.data.payLoad}) 
            localStorage.setItem('upnayanData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              upnayanData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              upnayanData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/lagnaData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data}) 
            setUserCallData({ lagnaData: res.data.payLoad}) 
            localStorage.setItem('lagnaData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              lagnaData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              lagnaData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/sankashtiData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data}) 
            setUserCallData({ sankashtiData: res.data.payLoad}) 
            localStorage.setItem('sankashtiData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              sankashtiData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              sankashtiData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/shaharData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data}) 
            setUserCallData({ shaharData: res.data.payLoad}) 
            localStorage.setItem('shaharData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              shaharData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              shaharData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/muhurtaSubtitle').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data}) 
            setUserCallData({ muhurtaSubtitle: res.data.payLoad}) 
            localStorage.setItem('muhurtaSubtitle', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              muhurtaSubtitle: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              muhurtaSubtitle: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/muhurtaType').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data}) 
            setUserCallData({ muhurtaType: res.data.payLoad}) 
            localStorage.setItem('muhurtaType', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              muhurtaType: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              muhurtaType: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/panchangData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data}) 
            setUserCallData({ panchangData: res.data.payLoad}) 
            localStorage.setItem('panchangData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              panchangData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              panchangData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

        axios.get('/application/grahaneData').then(res => {
          if (res.data.statusMessage === 'Success') {
            // categoriesDataUpdate({categories: res.data.data}) 
            setUserCallData({ grahaneData: res.data.payLoad}) 
            localStorage.setItem('grahaneData', JSON.stringify(res.data.payLoad));
            setCallResponses(prevState => ({
              ...prevState,
              grahaneData: true
            }))
          } else {
            setCallResponses(prevState => ({
              ...prevState,
              grahaneData: false
            }))
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })

      // }, 15000)
    }, [])

    function areAllValuesNotNull(obj) {
      return Object.values(obj).every(value => value !== null);
    }

    useEffect(() => {
      if(areAllValuesNotNull(callResponses)) {
        console.log(areAllValuesNotNull(callResponses));
        navigate('/tipani');
      }
    }, [callResponses])
    
  return (
    <div id="appCapsule" className="pt-5 bg-warning">
      <div className="error-page" style={{ padding: '0px', maxWidth: 'initial' }}>
        <div className="icon-box text-danger">
          <img
            src={Logo}
            alt="Brand"
            width="100%"
            style={{ maxWidth: '300px', borderRadius: '15px' }}
            className='border shadow'
          />
        </div>
        <button className="btn btn-light btn-lg rounded mb-1" disabled>
          <span
            className="spinner-grow spinner-grow-sm me-05"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { authToken } = auth

  return {
    authToken
  }
}

const mapDispatchToProps = {
  showAppToast,
  setUserCallData
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Load)
