import React from 'react';
import {Form, Button} from 'react-bootstrap';
import Logo from '../images/app_icon.png'

function RegistrationFormView (props) {
    return (
        <div id="appCapsule" className='bg-warning'>
            <div className="section mt-5">
                <div className="section mt-5 pt-5 bg-white rounded">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center mb-1">
                            <img src={Logo} className='rounded-3' width="100px" alt="Brand Logo" />
                        </div>
                        <div className="login-form">
                            <div className="section mt-2 mb-5">
                                <Form action="app-pages.html">

                                    <div className="form-group boxed">
                                        <Form.Group className="input-wrapper">
                                            <Form.Control type="text" className="form-control" id="name1" placeholder="नाव" />
                                        </Form.Group>
                                    </div>

                                    <div className="form-group boxed">
                                        <Form.Group className="input-wrapper">
                                            <Form.Control type="email" className="form-control" id="email1" placeholder="ई-मेल" />
                                        </Form.Group>
                                    </div>

                                    <div className="form-group boxed">
                                        <Form.Group className="input-wrapper">
                                            <Form.Control type="address" as="textarea" rows={3} className="form-control" id="address1" placeholder="पत्ता" />
                                        </Form.Group>
                                    </div>

                                    <Form.Group className="p-3">
                                        <Button variant="warning" className="btn btn-warning btn-block btn-lg">नोंदणी करा</Button>
                                    </Form.Group>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationFormView