import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from "react-redux"
import { Button, Table, Card, Form, Row, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ReturnDDMMYYYYMarathi } from '../../features/auth/authSlice'

function TithiView({dailyPanchang}) {

    const [startDate, setStartDate] = useState(null)
    const [convertedDate, setConverted] = useState(null)
    
    const [selectedMarTithi, setSelectedMarTithi] = useState('कृ प्रतिपदा')
    const [selectedMarMahina, setSelectedMarMahina] = useState('चैत्र')
    const [selectedMarVarsha, setSelectedMarVarsha] = useState('१९४२')

    const [selectedEngDate, setSelectedEngDate] = useState('१')
    const [selectedEngMonth, setSelectedEngMonth] = useState('१')
    const [selectedEngYear, setSelectedEngYear] = useState('२०२१')

    const [foundEngDate, setFoundEngDate] = useState(null)
    const [foundMarDate, setFoundMarDate] = useState(null)

    function EngDate(){
      const getEngFilteredDate = dailyPanchang.Success.filter((item) => (item.t1des === selectedMarTithi) && (item.mmthdes === selectedMarMahina) && (item.myr === selectedMarVarsha))
      setFoundEngDate(getEngFilteredDate[0] ? getEngFilteredDate[0] : null)
      console.log(getEngFilteredDate, selectedMarTithi, selectedMarMahina, selectedMarVarsha)
    }

    function MarDate(){
      const getMarFilteredDate = dailyPanchang.Success.filter((item) => (item.cdt === selectedEngDate) && (item.cmth === selectedEngMonth) && (item.cyr === selectedEngYear))
      setFoundMarDate(getMarFilteredDate[0] ? getMarFilteredDate[0] : null)
      console.log(getMarFilteredDate, selectedEngDate, selectedEngMonth, selectedEngYear)
    }

    useEffect(() => {
     if(startDate) setConverted(ReturnDDMMYYYYMarathi(startDate.toString()))
    }, [startDate])
  
    useEffect(() => {
      setStartDate(new Date())
    }, [])
  
    const PrevMonth = (dt) => {
      const newDate = new Date(dt);
      const month = newDate.getMonth() - 1;
      if (month === 0) {
        newDate.setFullYear(newDate.getFullYear() - 1);
        newDate.setMonth(11); // January
      } else {
        newDate.setMonth(month);
      }
    setStartDate(newDate)
  }
  
    const NextMonth = (dt) => {
        const newDate = new Date(dt);
        const month = newDate.getMonth() + 1;
        if (month === 12) {
          newDate.setFullYear(newDate.getFullYear() + 1);
          newDate.setMonth(0); // January
        } else {
          newDate.setMonth(month);
        }
      setStartDate(newDate)
    }

  return (
    <div id="appCapsule">
        <div className="section mt-1">
            <h3 className='text-center'>श्राद्ध तिथी</h3>
            {/* <div className="my-2 d-flex justify-content-between align-items-center">
                <Button variant="warning" size="sm" className="dateArrows" onClick={() => PrevMonth(startDate)}>
                    <i className="bi bi-caret-left-fill"></i>
                </Button>
                <div className='position-relative '>
                    <label htmlFor='panchangId'>
                    <p className="text-center m-0 fw-bold text-secondary">{convertedDate?.mahina} {convertedDate?.varsh}</p>
                    </label>
                    <DatePicker showMonthYearPicker showFullMonthYearPicker dateFormatCalendar='MMM yyyy' id="panchangId" className='KGdate PanchangMonthPicker' selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <Button variant="warning" size="sm" className="dateArrows" onClick={() => NextMonth(startDate)}>
                    <i className="bi bi-caret-right-fill"></i>
                </Button>
            </div> */}
        </div>
        <div className="section mt-3">
            <Row>
                <div className='col-4'>
                    <Form.Select onChange={(e) => setSelectedMarTithi(e.target.value)}>
                        <option value="कृ प्रतिपदा">कृ १</option>
                        <option value="कृ द्वितीया">कृ २</option>
                        <option value="कृ तृतीया">कृ ३</option>
                        <option value="कृ चतुर्थी">कृ ४</option>
                        <option value="कृ पंचमी">कृ ५</option>
                        <option value="कृ षष्ठी">कृ ६</option>
                        <option value="कृ सप्तमी">कृ ७</option>
                        <option value="कृ अष्टमी">कृ ८</option>
                        <option value="कृ नवमी">कृ ९</option>
                        <option value="कृ दशमी">कृ १०</option>
                        <option value="कृ एकादशी">कृ ११</option>
                        <option value="कृ द्वादशी">कृ १२</option>
                        <option value="कृ त्रयोदशी">कृ १३</option>
                        <option value="कृ चतुर्दशी">कृ १४</option>
                        <option value="अमावस्या">अमावस्या</option>
                        <option value="शु प्रतिपदा">शु १</option>
                        <option value="शु द्वितीया">शु २</option>
                        <option value="शु तृतीया">शु ३</option>
                        <option value="शु चतुर्थी">शु ४</option>
                        <option value="शु पंचमी">शु ५</option>
                        <option value="शु षष्ठी">शु ६</option>
                        <option value="शु सप्तमी">शु ७</option>
                        <option value="शु अष्टमी">शु ८</option>
                        <option value="शु नवमी">शु ९</option>
                        <option value="शु दशमी">शु १०</option>
                        <option value="शु एकादशी">शु ११</option>
                        <option value="शु द्वादशी">शु १२</option>
                        <option value="शु त्रयोदशी">शु १३</option>
                        <option value="शु चतुर्दशी">शु १४</option>
                        <option value="पौर्णिमा">पौर्णिमा</option>
                    </Form.Select>
                </div>
                <div className='col-4'>
                    <Form.Select onChange={(e) => setSelectedMarMahina(e.target.value)}>
                        <option value="चैत्र">चैत्र</option>
                        <option value="वैशाख">वैशाख</option>
                        <option value="ज्येष्ठ">ज्येष्ठ</option>
                        <option value="आषाढ">आषाढ</option>
                        <option value="श्रावण">श्रावण</option>
                        <option value="भाद्रपद">भाद्रपद</option>
                        <option value="आश्विन">आश्विन</option>
                        <option value="कार्त्तिक">कार्त्तिक</option>
                        <option value="मार्गशीर्ष">मार्गशीर्ष</option>
                        <option value="पौष">पौष</option>
                        <option value="माघ">माघ</option>
                        <option value="फाल्गुन">फाल्गुन</option>
                    </Form.Select>
                </div>
                <div className='col-4'>
                    <Form.Select onChange={(e) => setSelectedMarVarsha(e.target.value)}>
                        <option value="१९४२">१९४२</option>
                        <option value="१९४३">१९४३</option>
                        <option value="१९४४">१९४४</option>
                        <option value="१९४५">१९४५</option>
                    </Form.Select>
                </div>
                {/* <div className='col-3'>
                    <Button variant="warning" onClick={(e) => EngDate()}>इंग्रजी तारीख</Button>
                </div> */}
            </Row>
            <Row className='mt-2'>
              <Col className='d-flex justify-content-center'>
              <Button variant="warning" onClick={(e) => EngDate()}>इंग्रजी तारीख दाखवा</Button>
              </Col>
            </Row>
            <Row className='mt-2 mb-5'>
              <Col>
                <Card>
                  <Card.Body className='text-center p-1'>
                    {foundEngDate?.cdt} / {foundEngDate?.cmth} / {foundEngDate?.cyr}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <hr />
            <Row className='mt-5'>
                <div className='col-3'>
                    <Form.Select onChange={(e) => setSelectedEngDate(e.target.value)}>
                        <option value="१">1</option>
                        <option value="२">2</option>
                        <option value="३">3</option>
                        <option value="४">4</option>
                        <option value="५">5</option>
                        <option value="६">6</option>
                        <option value="७">7</option>
                        <option value="८">8</option>
                        <option value="९">9</option>
                        <option value="१०">10</option>
                        <option value="११">11</option>
                        <option value="१२">12</option>
                        <option value="१३">13</option>
                        <option value="१४">14</option>
                        <option value="१५">15</option>
                        <option value="१६">16</option>
                        <option value="१७">17</option>
                        <option value="१८">18</option>
                        <option value="१९">19</option>
                        <option value="२०">20</option>
                        <option value="२१">21</option>
                        <option value="२२">22</option>
                        <option value="२३">23</option>
                        <option value="२४">24</option>
                        <option value="२५">25</option>
                        <option value="२६">26</option>
                        <option value="२७">27</option>
                        <option value="२८">28</option>
                        <option value="२९">29</option>
                        <option value="३०">30</option>
                        <option value="३१">31</option>
                    </Form.Select>
                </div>
                <div className='col-5'>
                    <Form.Select onChange={(e) => setSelectedEngMonth(e.target.value)}>
                        <option value="१">January</option>
                        <option value="२">February</option>
                        <option value="३">March</option>
                        <option value="४">April</option>
                        <option value="५">May</option>
                        <option value="६">June</option>
                        <option value="७">July</option>
                        <option value="८">August</option>
                        <option value="९">September</option>
                        <option value="१०">October</option>
                        <option value="११">November</option>
                        <option value="१२">December</option>
                    </Form.Select>
                </div>
                <div className='col-4'>
                    <Form.Select onChange={(e) => setSelectedEngYear(e.target.value)}>
                        <option value="२०२१">2021</option>
                        <option value="२०२२">2022</option>
                        <option value="२०२३">2023</option>
                        <option value="२०२४">2024</option>
                    </Form.Select>
                </div>
                {/* <div className='col-3'>
                    <Button variant="warning" onClick={(e) => MarDate()}>मराठी तारीख</Button>
                </div> */}
            </Row>
            <Row className='mt-2'>
              <Col className='d-flex justify-content-center'>
                <Button variant="warning" onClick={(e) => MarDate()}>मराठी तारीख दाखवा</Button>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <Card>
                  <Card.Body className='text-center p-1'>
                    {foundMarDate?.t1des} / {foundMarDate?.mmthdes} / {foundMarDate?.myr}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
        </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
    const {
      otpRequestSuccess,
      dailyPanchang
    } = auth
    return {
      otpRequestSuccess,
      dailyPanchang
    }
  }
  
  const mapDispatchToProps = {
    ReturnDDMMYYYYMarathi
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(TithiView)