import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { setToken, setUserData, setUserCallData } from './features/auth/authSlice';
import Authentication from './components/Pages/Authentication';
import Layout from './components/Pages/Layout';
import Load from './components/Pages/Load';
import AppInfo from './components/Pages/AppInfo';
import RegistrationForm from './components/Pages/RegistrationForm'
import Tipani from './components/Pages/Tipani';
import Grahane from './components/Pages/Grahane';
import Muhurta from './components/Pages/Muhurta';
import Shubhadivas from './components/Pages/Shubhadivas';
import SankashtiChandroday from './components/Pages/SankashtiChandroday';
import GrahaBadal from './components/Pages/GrahaBadal';
import AgniAhuti from './components/Pages/AgniAhuti';
import LopDarshan from './components/Pages/LopDarshan';
import AmrutsidhhiYog from './components/Pages/AmrutsidhhiYog';
import Gurupushpamrut from './components/Pages/Gurupushpamrut';
import DhanishthaPanchak from './components/Pages/DhanishthaPanchak';
import ParjanyaNakshatra from './components/Pages/ParjanyaNakshatra';
import Dinvishesh from './components/Pages/Dinvishesh';
import Tithi from './components/Pages/Tithi';
import Panchang from './components/Pages/Panchang';
import jwt_decode from 'jwt-decode';
import GetStarted from './components/Pages/GetStarted';
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';
import Summary from './components/Pages/Summary'

const App = ({
  authToken,
  setToken,
  setUserData,
  setUserCallData
}) => {
  // if ((localStorage.getItem('Orders') === null && localStorage.getItem('Purchase') === null && localStorage.getItem('Wastage') === null) || (localStorage.getItem('Orders').length <= 2 && localStorage.getItem('Purchase').length <= 2 && localStorage.getItem('Wastage').length <= 2)) {
  //   JSON.stringify(localStorage.setItem('Orders', '[]'))
  //   JSON.stringify(localStorage.setItem('Purchase', '[]'))
  //   JSON.stringify(localStorage.setItem('Wastage', '[]'))    
  //   JSON.stringify(localStorage.setItem('Carts', '[]'))    
  // }
  // const navigate = useNavigate();
  const validateToken = () => {
    if (authToken) {
      return true
    } else {
      const token = localStorage.getItem('token')
      const tokenData = (token) ? jwt_decode(token) : false
      if (tokenData && new Date(tokenData.exp * 1000) > new Date()) {
        // if (tokenData.userInfo.hasOwnProperty('ID') && tokenData.userInfo.hasOwnProperty('RoleID')) {
          setToken({ authToken: token })
          return true
        // } else {
        //   localStorage.clear()
        //   return false
        // }
      } else {
        localStorage.clear()
        return false
      }
    }
  }
  const userLoggedIn = validateToken()
  // console.log(userLoggedIn)

  useEffect(() => {
    if(localStorage.getItem('token')) {

      const userToken = localStorage.getItem('token');
      setToken({ authToken: userToken });

      if(localStorage.getItem('UserData')) {
        const user = JSON.parse(localStorage.getItem('UserData'))
        setUserData({'userData': user[0]});
      }

      if(localStorage.getItem('agniData')) {
        const agniData = JSON.parse(localStorage.getItem('agniData'))
        setUserCallData({'agniData': agniData});
      }

      if(localStorage.getItem('grahaRashiBadalData')) {
        const grahaRashiBadalData = JSON.parse(localStorage.getItem('grahaRashiBadalData'))
        setUserCallData({'grahaRashiBadalData': grahaRashiBadalData});
      }

      if(localStorage.getItem('ahutiData')) {
        const ahutiData = JSON.parse(localStorage.getItem('ahutiData'))
        setUserCallData({'ahutiData': ahutiData});
      }

      if(localStorage.getItem('parjanyaNakshtraData')) {
        const parjanyaNakshtraData = JSON.parse(localStorage.getItem('parjanyaNakshtraData'))
        setUserCallData({'parjanyaNakshtraData': parjanyaNakshtraData});
      }

      if(localStorage.getItem('dhanishtaPanchakData')) {
        const dhanishtaPanchakData = JSON.parse(localStorage.getItem('dhanishtaPanchakData'))
        setUserCallData({'dhanishtaPanchakData': dhanishtaPanchakData});
      }

      if(localStorage.getItem('gurupushamrutYogData')) {
        const gurupushamrutYogData = JSON.parse(localStorage.getItem('gurupushamrutYogData'))
        setUserCallData({'gurupushamrutYogData': gurupushamrutYogData});
      }

      if(localStorage.getItem('amrutsidhiYogData')) {
        const amrutsidhiYogData = JSON.parse(localStorage.getItem('amrutsidhiYogData'))
        setUserCallData({'amrutsidhiYogData': amrutsidhiYogData});
      }

      if(localStorage.getItem('lopDarshanData')) {
        const lopDarshanData = JSON.parse(localStorage.getItem('lopDarshanData'))
        setUserCallData({'lopDarshanData': lopDarshanData});
      }

      if(localStorage.getItem('shubhDivasData')) {
        const shubhDivasData = JSON.parse(localStorage.getItem('shubhDivasData'))
        setUserCallData({'shubhDivasData': shubhDivasData});
      }

      if(localStorage.getItem('muhurtaData')) {
        const muhurtaData = JSON.parse(localStorage.getItem('muhurtaData'))
        setUserCallData({'muhurtaData': muhurtaData});
      }

      if(localStorage.getItem('dailyPanchang')) {
        const dailyPanchang = JSON.parse(localStorage.getItem('dailyPanchang'))
        setUserCallData({'dailyPanchang': dailyPanchang});
      }

      if(localStorage.getItem('upnayanData')) {
        const upnayanData = JSON.parse(localStorage.getItem('upnayanData'))
        setUserCallData({'upnayanData': upnayanData});
      }

      if(localStorage.getItem('lagnaData')) {
        const lagnaData = JSON.parse(localStorage.getItem('lagnaData'))
        setUserCallData({'lagnaData': lagnaData});
      }

      if(localStorage.getItem('sankashtiData')) {
        const sankashtiData = JSON.parse(localStorage.getItem('sankashtiData'))
        setUserCallData({'sankashtiData': sankashtiData});
      }

      if(localStorage.getItem('shaharData')) {
        const shaharData = JSON.parse(localStorage.getItem('shaharData'))
        setUserCallData({'shaharData': shaharData});
      }
      
      if(localStorage.getItem('muhurtaSubtitle')) {
        // console.log(localStorage.getItem('muhurtaSubtitle'));
        const muhurtaSubtitle = JSON.parse(localStorage.getItem('muhurtaSubtitle'))
        setUserCallData({'muhurtaSubtitle': muhurtaSubtitle});
      }

      if(localStorage.getItem('muhurtaType')) {
        const muhurtaType = JSON.parse(localStorage.getItem('muhurtaType'))
        setUserCallData({'muhurtaType': muhurtaType});
      }

      if(localStorage.getItem('panchangData')) {
        const panchangData = JSON.parse(localStorage.getItem('panchangData'))
        setUserCallData({'panchangData': panchangData});
      }

      if(localStorage.getItem('grahaneData')) {
        const grahaneData = JSON.parse(localStorage.getItem('grahaneData'))
        setUserCallData({'grahaneData': grahaneData});
      }

      // navigate('/load');
    }
  }, [])
  
  
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        {!userLoggedIn &&
          <Fragment>
            <Route path="*" element={<Auth />} />
            <Route path="load" element={<SelectWebsiteList />} />
            <Route path="getStarted" element={<GetStartedView />} />
            {/* <Route path="getStartedLogin" element={<GetStartedLoginView />} /> */}
            {/* <Route path="/" element={<SelectWebsiteList />} /> */}
          </Fragment>
        }
        {userLoggedIn &&
          <Fragment>
            <Route path="/" element={<SelectWebsiteList />} />
            <Route path="getStarted" element={<GetStartedView />} />
            <Route path="appinfo" element={<AboutApp />} />
            <Route path="tipani" element={<TipaniView />} />
            <Route path="grahane" element={<GrahaneView />} />
            <Route path="muhurta" element={<MuhurtaView />} />
            <Route path='shubhadivas' element={<ShubhadivasView />} />
            <Route path='sankashtiChandroday' element={<SankashtiChandrodayView />} />
            <Route path="grahaBadal" element={<GrahaBadalView />} />
            <Route path='agniAhuti' element={<AgniAhutiView />} />
            <Route path='lopDarshan' element={<LopDarshanView />} />
            <Route path='amrutsidhhiYog' element={<AmrutsidhhiYogView />} />
            <Route path='gurupushpamrut' element={<GurupushpamrutView />} />
            <Route path='dhanishthaPanchak' element={<DhanishthaPanchakView />} />
            <Route path='parjanyaNakshatra' element={<ParjanyaNakshatraView />} />
            <Route path='dinvishesh' element={<DinvisheshView />} />
            <Route path='tithi' element={<TithiView />} />
            <Route path='panchang' element={<PanchangView />} />
            <Route path="register" element={<RegistrationFormView />} />
            <Route path="summary" element={<SummaryView />} />
            <Route path="load" element={<SelectWebsiteList />} />
            <Route path="*" element={<Auth />} />
          </Fragment>
        }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function Auth() {
  return (
    <div className="App pt-5">
      <Authentication></Authentication>
    </div>
  )
}

function SelectWebsiteList() {
  return (
    <div className="App">
      <Load></Load>
    </div>
  )
}

function AboutApp() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Header></Header>
        <AppInfo></AppInfo>
      <Footer></Footer>
      {/* </Layout> */}
    </div>
  )
}

function TipaniView() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Header></Header>
        <Tipani></Tipani>
      <Footer></Footer>
      {/* </Layout> */}
    </div>
  )
}

function GrahaneView() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Header></Header>
        <Grahane></Grahane>
      <Footer></Footer>
      {/* </Layout> */}
    </div>
  )
}

function MuhurtaView() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Header></Header>
        <Muhurta></Muhurta>
      <Footer></Footer>
      {/* </Layout> */}
    </div>
  )
}

function ShubhadivasView() {
  return (
    <div className='App'>
      <Header></Header>
        <Shubhadivas></Shubhadivas>
      <Footer></Footer>
    </div>
  )
}

function SankashtiChandrodayView() {
  return (
    <div className='App'>
      <Header></Header>
        <SankashtiChandroday></SankashtiChandroday>
      <Footer></Footer>
    </div>
  )
}

function GrahaBadalView() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Header></Header>
        <GrahaBadal></GrahaBadal>
      <Footer></Footer>
      {/* </Layout> */}
    </div>
  )
}

function AgniAhutiView() {
  return (
    <div className='App'>
      <Header></Header>
        <AgniAhuti></AgniAhuti>
      <Footer></Footer>
    </div>
  )
}

function LopDarshanView() {
  return (
    <div className='App'>
      <Header></Header>
        <LopDarshan></LopDarshan>
      <Footer></Footer>
    </div>
  )
}

function AmrutsidhhiYogView() {
  return (
    <div className='App'>
      <Header></Header>
        <AmrutsidhhiYog></AmrutsidhhiYog>
      <Footer></Footer>
    </div>
  )
}

function GurupushpamrutView() {
  return (
    <div className='App'>
      <Header></Header>
        <Gurupushpamrut></Gurupushpamrut>
      <Footer></Footer>
    </div>
  )
}

function DhanishthaPanchakView() {
  return (
    <div className='App'>
      <Header></Header>
        <DhanishthaPanchak></DhanishthaPanchak>
      <Footer></Footer>
    </div>
  )
}

function ParjanyaNakshatraView() {
  return (
    <div className='App'>
      <Header></Header>
        <ParjanyaNakshatra></ParjanyaNakshatra>
      <Footer></Footer>
    </div>
  )
}

function DinvisheshView() {
  return (
    <div className='App'>
      <Header></Header>
        <Dinvishesh></Dinvishesh>
      <Footer></Footer>
    </div>
  )
}

function TithiView() {
  return (
    <div className='App'>
      <Header></Header>
        <Tithi></Tithi>
      <Footer></Footer>
    </div>
  )
}

function PanchangView() {
  return (
    <div className='App'>
      <Header></Header>
        <Panchang></Panchang>
      <Footer></Footer>
    </div>
  )
}

function RegistrationFormView() {
  return (
    <div className="App">
      {/* <Layout> */}
        <RegistrationForm></RegistrationForm>
      {/* </Layout> */}
    </div>
  )
}

function GetStartedView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <GetStarted></GetStarted>
    {/* </Layout> */}
    </div>
  )
}

function SummaryView() {
  return (
    <div className="App">
    <Layout>
      <Summary></Summary>
    </Layout>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const {
    authToken
  } = auth

  return {
    authToken
  }
}

const mapDispatchToProps = {
  setToken,
  setUserData,
  setUserCallData
}

export default connect(mapStateToProps, mapDispatchToProps)(App)