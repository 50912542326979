import React from 'react'

function GetStarted(props) {
    return (
        <div id="appCapsule">
            <div className="error-page">
                <img src="assets/img/getstarted/website-automation.svg" alt="Error" className="imaged square w-100" />
                <h1 className="title">Build Instant Website In 30 Seconds</h1>
                <a href='https://webweb.in' target="_blank" rel="noreferrer">
                    <button className="btn-lg btn-primary">Build Website</button>
                </a>
            </div>
        </div>
    )
}

export default GetStarted