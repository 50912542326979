import React from 'react'
import Logo from '../images/app_icon.png'

function AppInfo(props) {
    return (
        <div id="appCapsule">
            <div className="section mt-5">
                <div className="section mt-5">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center mb-1">
                            <img src={Logo} className='rounded-3' width="180px" alt="Brand Logo" />
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <h2 className="text-warning">श्री स्वामी समर्थ पंचांग २०२३</h2>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <span ng-if="appType == 1" className="text-center" ng-click="toggleID('deviceVersion')">v2.0.0</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppInfo