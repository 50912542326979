import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../AxiosConfig'
import config from '../../config'
import jwt_decode from 'jwt-decode'
import { tr } from 'faker/lib/locales'

export const requestOtp = createAsyncThunk(
  'auth/requestOtp',
  async (params) => {
    const { mobileNumber } = params;
    localStorage.setItem('KGurujiMobileNumber', mobileNumber)
    const response = await axios.get(config.apiUrl + '/application/checkUserStatus/'+ mobileNumber)
    const { data } = response
    return data
  }
)

export const regitrationUser = createAsyncThunk(
  'auth/regitrationUser',
  async (params) => {
    const { username, mobile, email, address } = params;
    const formData = new FormData();
    formData.append("username", username);
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("address", address);
    // localStorage.setItem('KGurujiMobileNumber', mobileNumber)
    const response = await axios.post(config.apiUrl + '/application/createUser', formData)
    const { data } = response
    return data
  }
)

export const validateOtp = createAsyncThunk(
  'auth/validateOtp',
  async (params) => {
    const { mobileNumber, otp } = params;
    const formData = new FormData();
    formData.append("username", mobileNumber);
    formData.append("password", otp);
    const response = await axios.post(config.apiUrl + '/application/login', formData)
    const { data } = response
    return data
  }
)

const initialState = {
  authToken: null,
  hideSendOtpButton: false,
  showVerifyOtpForm: false,
  showRegistrationForm: false,
  showLoginForm: true,
  otpRequestSuccess: false,
  otpRequestError: false,
  otpValidateSuccess: false,
  otpValidateError: false,
  role: null,
  userData: null,
  agniData: null,
  grahaRashiBadalData: null,
  ahutiData: null,
  parjanyaNakshtraData: null,
  dhanishtaPanchakData: null,
  gurupushamrutYogData: null,
  amrutsidhiYogData: null,
  lopDarshanData: null,
  shubhDivasData: null,
  muhurtaData: null,
  dailyPanchang: null,
  upnayanData: null,
  lagnaData: null,
  sankashtiData: null,
  shaharData: null,
  muhurtaSubtitle: null,
  muhurtaType: null,
  panchangData: null,
  grahaneData:null,
  citySelect: { 'city': 'MUMBAI', 'shahar': 'मुंबई' },
  allCities: [
    { 'city': 'MUMBAI', 'shahar': 'मुंबई' },
    { 'city': 'THANE', 'shahar': 'ठाणे' },
    { 'city': 'PUNE', 'shahar': 'पुणे' },
    { 'city': 'RATNAGIRI', 'shahar': 'रत्नागिरी' },
    { 'city': 'KOLHAPUR', 'shahar': 'कोल्हापूर' },
    { 'city': 'SATARA', 'shahar': 'सातारा' },
    { 'city': 'NASHIK', 'shahar': 'नाशिक' },
    { 'city': 'AHMEDNAGAR', 'shahar': 'अहमदनगर' },
    { 'city': 'PANJI', 'shahar': 'पणजी' },
    { 'city': 'DHULE', 'shahar': 'धुळे' },
    { 'city': 'JALGAON', 'shahar': 'जळगाव' },
    { 'city': 'WARDHA', 'shahar': 'वर्धा' },
    { 'city': 'YAWATMAL', 'shahar': 'यवतमाळ' },
    { 'city': 'BEED', 'shahar': 'बीड' },
    { 'city': 'SANGALI', 'shahar': 'सांगली' },
    { 'city': 'SAWANTWADI', 'shahar': 'सावंतवाडी' },
    { 'city': 'SOLAPUR', 'shahar': 'सोलापूर' },
    { 'city': 'NAGPUR', 'shahar': 'नागपूर' },
    { 'city': 'AMRAVTI', 'shahar': 'अमरावती' },
    { 'city': 'AKOLA', 'shahar': 'अकोला' },
    { 'city': 'AURANGABAD', 'shahar': 'औरंगाबाद' },
    { 'city': 'BHUSAWAL', 'shahar': 'भुसावळ' },
    { 'city': 'PARBHANI', 'shahar': 'परभणी' },
    { 'city': 'NANDED', 'shahar': 'नांदेड' },
    { 'city': 'OSMANABAD', 'shahar': 'उस्मानाबाद' },
    { 'city': 'BHANDARA', 'shahar': 'भंडारा' },
    { 'city': 'CHANDRAPUR', 'shahar': 'चंद्रपूर' },
    { 'city': 'BULDHANA', 'shahar': 'बुलढाणा' },
    { 'city': 'INDORE', 'shahar': 'इंदोर' },
    { 'city': 'GWALIAR', 'shahar': 'ग्वाल्हेर' },
    { 'city': 'BELGAON', 'shahar': 'बेळगाव' },
    { 'city': 'GULBARGA', 'shahar': 'गुलबर्गा' },
    { 'city': 'VADODARA', 'shahar': 'वडोदरा' },
  ],
  StartEnable: false,
  EndEnable: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      localStorage.clear()
      state.hideSendOtpButton = false
      state.showVerifyOtpForm = false
      state.showRegistrationForm = false
      state.showLoginForm = true
      state.otpRequestSuccess = false
      state.otpRequestError = false
      state.otpValidateSuccess = false
      state.otpValidateError = false
      state.authToken = null
      state.role = null
    },
    backToLogin: (state) => {
      state.hideSendOtpButton = false
      state.showVerifyOtpForm = false
      state.showRegistrationForm = false
      state.showLoginForm = true
      state.otpRequestSuccess = false
      state.otpRequestError = false
      state.otpValidateSuccess = false
      state.otpValidateError = false
      state.role = null
      localStorage.clear()
    },
    setToken: (state, action) => {
      state.authToken = action.payload.authToken
      const tokenData = jwt_decode(action.payload.authToken);
      state.role = 'User';
    },
    setUserData: (state, action) => {
      state.userData = action.payload.userData;
      const getsDate = new Date(action.payload.userData.StartDate)
        const y = getsDate.getFullYear()
        state.StartEnable = y

        const getsDate2 = new Date(action.payload.userData.EndDate)
        const y2 = getsDate2.getFullYear();
        state.EndEnable = y2
    },
    setUserCallData: (state, action) => {
      const { agniData, grahaRashiBadalData, ahutiData, parjanyaNakshtraData, dhanishtaPanchakData, gurupushamrutYogData, amrutsidhiYogData, lopDarshanData, shubhDivasData, muhurtaData, dailyPanchang, upnayanData, lagnaData, sankashtiData, shaharData, muhurtaSubtitle, muhurtaType, panchangData, grahaneData } = action.payload
      if(agniData) state.agniData = agniData
      if(grahaRashiBadalData) state.grahaRashiBadalData = grahaRashiBadalData
      if(ahutiData) state.ahutiData = ahutiData
      if(parjanyaNakshtraData) state.parjanyaNakshtraData = parjanyaNakshtraData
      if(dhanishtaPanchakData) state.dhanishtaPanchakData = dhanishtaPanchakData
      if(gurupushamrutYogData) state.gurupushamrutYogData = gurupushamrutYogData
      if(amrutsidhiYogData) state.amrutsidhiYogData = amrutsidhiYogData
      if(lopDarshanData) state.lopDarshanData = lopDarshanData
      if(shubhDivasData) state.shubhDivasData = shubhDivasData
      if(muhurtaData) state.muhurtaData = muhurtaData
      if(dailyPanchang) state.dailyPanchang = dailyPanchang
      if(upnayanData) state.upnayanData = upnayanData
      if(lagnaData) state.lagnaData = lagnaData
      if(sankashtiData) state.sankashtiData = sankashtiData
      if(shaharData) state.shaharData = shaharData
      if(muhurtaSubtitle) state.muhurtaSubtitle = muhurtaSubtitle
      if(muhurtaType) state.muhurtaType = muhurtaType
      if(panchangData) state.panchangData = panchangData
      if(grahaneData) state.grahaneData = grahaneData
    },
    setCity: (state, action) => {
      state.citySelect = action.payload.City
    }
  },
  extraReducers: {
    [requestOtp.fulfilled]: (state, action) => {
      const {statusMessage, payLoad}  = action.payload
      if (statusMessage === 'Success') {
        if (payLoad.Success === 'Member with this mobile number is already register') {
          state.hideSendOtpButton = true
          state.showVerifyOtpForm = true
          state.otpRequestSuccess = true
        } else if (payLoad.Success === 'No data found') {
          state.showRegistrationForm = true
          state.hideSendOtpButton = true
        } else {
          state.otpRequestError = true
        }
      } else {
        state.hideSendOtpButton = false
        state.showVerifyOtpForm = false
        state.showRegistrationForm = false
      }
    },
    // [requestOtp.pending]: (state, action) => {
    //   state.someValue += action.payload
    // },
    // [requestOtp.rejected]: (state, action) => {
    //   state.someValue += action.payload
    // },
    [validateOtp.fulfilled]: (state, action) => {
      const { statusCode, statusMessage, payLoad } = action.payload
      if (payLoad !== 'Please Enter Correct OTP') {
        localStorage.setItem('token', payLoad.token)
        localStorage.setItem('UserData', JSON.stringify(payLoad.UserData))
        state.authToken = payLoad.token
        state.otpValidateSuccess = true
        state.userData = payLoad.UserData[0];
        const getsDate = new Date(payLoad.UserData[0].StartDate)
        const y = getsDate.getFullYear()
        state.StartEnable = y

        const getsDate2 = new Date(payLoad.UserData[0].EndDate)
        const y2 = getsDate2.getFullYear();
        state.EndEnable = y2
      } else {
        state.otpValidateError = true
        state.authToken = null
      }
    },
    [regitrationUser.fulfilled]: (state, action) => {
      const { statusCode, statusMessage, payLoad } = action.payload
      console.log(action)
      if(payLoad.Success == 'User created successfully') {
        state.showVerifyOtpForm = true
        state.otpRequestSuccess = true
      } else {
        state.otpRequestError = true
      }
      // if (payLoad !== 'Please Enter Correct OTP') {
      //   localStorage.setItem('token', payLoad.token)
      //   localStorage.setItem('UserData', JSON.stringify(payLoad.UserData))
      //   state.authToken = payLoad.token
      //   state.otpValidateSuccess = true
      // } else {
      //   state.otpValidateError = true
      //   state.authToken = null
      // }
    },
    // [validateOtp.pending]: (state, action) => {
    //   state.someValue += action.payload
    // },
    // [validateOtp.rejected]: (state, action) => {
    //   state.someValue += action.payload
    // }
  }
})

export const { logout, backToLogin, setToken, setUserData, setUserCallData, setCity } = authSlice.actions;

function toMarathiNumber(number) {
  // Define an array of Marathi digits
  const marathiDigits = ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'];

  // Convert the number to a string
  const numberString = String(number);

  // Convert each digit to its Marathi equivalent
  return numberString.split('').map(digit => marathiDigits[parseInt(digit)]).join('');
}

const Months = ['जानेवारी', 'फेब्रुवारी', 'मार्च', 'एप्रिल', 'मे', 'जून', 'जुलै', 'ऑगस्ट', 'सप्टेंबर', 'ऑक्टोबर', 'नोव्हेंबर', 'डिसेंबर']
export function ReturnDDMMYYYYMarathi(date) {
  // let newdate = '';
  const getsDate = new Date(date);
  const d = getsDate.getDate();
  const m = getsDate.getMonth();
  const y = getsDate.getFullYear();
  const MarathiDate = {
    taarikh: toMarathiNumber(d),
    mahina: Months[m],
    mahinaAnk: toMarathiNumber(m + 1),
    varsh: toMarathiNumber(y),
    date: d,
    month: m,
    year: y
  }

  return MarathiDate
}

export default authSlice.reducer
