import React, { useEffect, useState, useRef, useMemo } from "react"
import config from '../../config'
import { connect } from "react-redux"
import {Modal, Button, Form } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from 'axios'
import 'react-phone-number-input/style.css'
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'
import AppToast from "../Elements/Notification/AppToast"
import { showAppToast, hideAppToast } from '../../features/utilities/appToastSlice'
import Countdown, { zeroPad } from 'react-countdown'
import { requestOtp, validateOtp, backToLogin, regitrationUser } from "../../features/auth/authSlice"
import Logo from '../images/app_icon.png'

const Authentication = ({
  showAppToast,
  appToastVisible,
  appToastMessage,
  appToastIcon,
  appToastBackground,
  appToastPosition,
  requestOtp,
  validateOtp,
  otpRequestSuccess,
  otpRequestError,
  hideSendOtpButton,
  showVerifyOtpForm,
  showRegistrationForm,
  otpValidateSuccess,
  otpValidateError,
  backToLogin,
  isVisible,
  regitrationUser
}) => {

  const inputRef = useRef(null);
  const emailInputRef = useRef(null)
  const navigate = useNavigate()
  const [mobileNumber, setMobileNumber] = useState("")
  const [mobileNumberCC, setMobileNumberCC] = useState("+91")
  const [disableVerifyOtpButton, setDisableVerifyOtpButton] = useState(false)
  const [logo, setAppLogo] = useState("")
  const [disableOtp, setDisableOtp] = useState(true)

  const [GetUserData, setGetUserData] = useState({
    'username': '',
    'email': '',
    'address': '',
  })

  useEffect(() => {
    if(localStorage.getItem('token')) {
      navigate('/tipani')
    }
  }, [])

  useEffect(() => {
    if (otpRequestSuccess) {
      showAppToast({ toastMessage: 'OTP SENT', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
    }
  }, [otpRequestSuccess, showAppToast])

  useEffect(() => {
    if (otpRequestError) {
      showAppToast({ toastMessage: 'Error', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center', isVisible: true })
    }
  }, [otpRequestError, showAppToast])

  useEffect(() => {
    if (otpValidateSuccess) {
      showAppToast({ toastMessage: 'OTP SENT', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
      setDisableVerifyOtpButton(true)
      console.log("dhcghdc")
      navigate('/load')
    }
  }, [otpValidateSuccess, showAppToast, setDisableVerifyOtpButton])

  useEffect(() => {
    if (otpValidateError) {
      showAppToast({ toastMessage: 'Error', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center', isVisible: true })
      setDisableVerifyOtpButton(false)
    }
  }, [otpValidateError, showAppToast, setDisableVerifyOtpButton])

  const handleUserNameValidation = (e) => {
    e.preventDefault()
    // setSendOtpButton(true)
    requestOtp({ mobileNumber: mobileNumber.slice(3) })
  }

  const HandleOtpValidation = (e) => {
    e.preventDefault()
    setDisableVerifyOtpButton(true)
    const otp = e.target.otp.value
    validateOtp({ mobileNumber: mobileNumber.slice(3), otp })
  }

  const [timer, setTimer] = useState(Date.now() + 59999)


  const reSendOtp = (e) => {
    e.preventDefault()
    axios.get(config.apiUrl + '/user/login?mobile='+ mobileNumber).then(res=>{

        if (res.data.message === 'Success') {
          // setResMessage(res.data.data)
          setDisableOtp(true)
          showAppToast({ toastMessage:'OTP Sent', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
          setTimer(Date.now() + 59999)
        } else {
          showAppToast({ toastMessage:'Something Went Wrong', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center', isVisible: true })
        }
    })
  }

  const handleBackToLogin = () => {
    backToLogin()
  }

  const Renderer = () => {
    setDisableOtp(false)
  }

  const countdownRenderer = ({ minutes, seconds }) => {
    return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>
  }

  useEffect(() => {
    setTimer(Date.now() + 59999)
  }, [showVerifyOtpForm])

  const countDownTimer = useMemo(() => {
    console.log(timer)
    return <Countdown
    key={timer}
    date={timer}
    onComplete={Renderer}
    renderer={countdownRenderer}
  />
  }, [timer])

  // axios.get(config.apiUrl + '/Ai/readWhitelabelJSON/its.me.in').then(res=>{
  //     console.log(res)
  //     if (res.data.message === 'Success') {
  //       setAppLogo(res.data.data.logo)
  //       localStorage.setItem('AppLogo', res.data.data.logo);
  //       console.log(logo)
  //     } else {
  //       showAppToast({ toastMessage:'Something Went Wrong', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
  //     }
  // })

  const [showEmailInput, setShowEmailInput] = useState(false)
  const [emailInput, setEmailInput] = useState(false)
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false)
  
  const handleUserNameInput = (event) => {
    const value = event.target.value
    console.log(value)
    if (value) {
      if (isNaN(value)) {
        setShowEmailInput(true)
        setShowPhoneNumberInput(false)
        setEmailInput(value)
      } else {
        setMobileNumber(`${mobileNumberCC}${value}`)
        setShowEmailInput(false)
        setShowPhoneNumberInput(true)
      }
    } else {
      setShowEmailInput(false)
      setShowPhoneNumberInput(false)
      setEmailInput('')
      setMobileNumber('')
    }
  }

  useEffect(() => {
    if (setShowPhoneNumberInput && inputRef && mobileNumber.length <= 4) {
      inputRef?.current?.focus();
    }
  }, [setShowPhoneNumberInput, mobileNumber])

  useEffect(() => {
    if (showEmailInput && emailInputRef && emailInput.length <= 2) {
      emailInputRef?.current?.focus();
    }
  }, [emailInput, showEmailInput])

  const handlePhoneNumberInput = (number) => {
    console.log(number, mobileNumber, mobileNumberCC)
    const codeLength = mobileNumberCC.length
    if (number) {
      if (number.slice(0, codeLength) === mobileNumberCC) {
        setMobileNumber(number)
      } else {
        setMobileNumber(mobileNumber.replace(mobileNumberCC, number))
      }
    } else {
      console.log('else', number)
      setMobileNumber('')
      setEmailInput('')
      setShowEmailInput(false)
      setShowPhoneNumberInput(false)
    }
  }

  const handleEmailInput = (event) => {
    const value = event.target.value
    if (value) {
      setEmailInput(value)
    } else {
      setShowEmailInput(false)
      setShowPhoneNumberInput(false)
      setEmailInput('')
      setMobileNumber('')
    }
  }

  const handleCountryChange = (event) => {
    const countryCode = getCountryCallingCode(event)
    setMobileNumberCC(`+${getCountryCallingCode(event)}`)
    if (countryCode !== '91') {
      setEmailInput('')
      setShowEmailInput(true)
    } else {
      setShowEmailInput(false)
      setEmailInput('')
    }
  }

  const updateUserData = (e) => {
    const field = e.target.name;
    setGetUserData(prevState => ({
      ...prevState,
      [field] : e.target.value
    }));
  }

  const hideRegisterNowButton = () => {
  }
  
  const handleUserAdd = (e) => {
    e.preventDefault()
    console.log(GetUserData)
    // setSendOtpButton(true)
    regitrationUser({...GetUserData, 'mobile': mobileNumber.slice(3) })
  }
  

  return (
    <div className="bg-warning d-flex justify-content-center" style={{height: '100vh', marginTop: '-40px', alignItems: 'center'}}>
      <AppToast></AppToast>
      <div className='card w-75'>
        <div className="card-body">
      {!showVerifyOtpForm && <>
      {
        showRegistrationForm ?
        <><div className="row">
            <div className="col-12 d-flex flex-column align-items-center justify-content-center mb-1">
                <img src={Logo} className='rounded-3' width="100px" alt="Brand Logo" />
                <p className="text-dark fw-bold text-center m-0 mt-1">श्री स्वामी समर्थ पंचांग २०२४</p>
                <p className="text-warning fw-bold text-center m-0 mt-2 fs-4">नोंदणी</p>
            </div>
            <div className="login-form">
                <div className="section mt-0 mb-5">
                    <Form onSubmit={handleUserAdd}>

                        <div className="form-group boxed">
                            <Form.Group className="input-wrapper">
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="name1"
                                  placeholder="नाव"
                                  name="username"
                                  value={GetUserData?.username}
                                  onChange={updateUserData}
                                  required
                                />
                            </Form.Group>
                        </div>

                        <div className="form-group boxed">
                            <Form.Group className="input-wrapper">
                                <Form.Control
                                  type="email"
                                  className="form-control"
                                  id="email1"
                                  placeholder="ई-मेल"
                                  name="email"
                                  value={GetUserData?.email}
                                  onChange={updateUserData}
                                  required
                                />
                            </Form.Group>
                        </div>

                        <div className="form-group boxed">
                            <Form.Group className="input-wrapper">
                                <Form.Control
                                  type="address"
                                  as="textarea"
                                  rows={3}
                                  className="form-control"
                                  id="address1"
                                  name="address"
                                  placeholder="पत्ता"
                                  value={GetUserData?.address}
                                  onChange={updateUserData}
                                  required
                                />
                            </Form.Group>
                        </div>

                        <Form.Group className="p-3">
                          <Button
                            type="submit"
                            variant="warning"
                            className="btn btn-warning btn-block btn-lg">नोंदणी करा</Button>
                        </Form.Group>

                    </Form>
                </div>
            </div>
        </div></>
        :
        <div className="login-form">
          <div className="section">
            <img
              src={Logo}
              // https://webweb.ams3.cdn.digitaloceanspaces.com/webweb.svg
              alt="Brand Logo"
              className="form-image"
            />
          </div>
          <div className="section mt-1">
            {/* <h1>Authentication</h 1> */}
            {/* <h4>login to manage your websites</h4> */}
          </div>
          <div className="section mt-1">
            <form onSubmit={handleUserNameValidation}>
              <div className="form-group boxed">
                <div className="input-wrapper">
                {
                  showPhoneNumberInput &&
                  <PhoneInput
                    className="form-control phoneInput mt-1"
                    international
                    defaultCountry="IN"
                    value={`${mobileNumber}`}
                    onChange={handlePhoneNumberInput}
                    onCountryChange={handleCountryChange}
                    ref={inputRef}
                    maxLength={15}
                    countryCallingCodeEditable={false}
                  />
                }
                {
                  !showPhoneNumberInput && !showEmailInput &&
                  <input
                    type="tel"
                    className="form-control mt-1"
                    id="number"
                    placeholder="मोबाईल नंबर"
                    maxLength={10}
                    required name="mobileNumber"
                    onChange={handleUserNameInput}
                  />
                }
                {
                  showEmailInput && <input
                  className="form-control mt-1"
                  id="email"
                  placeholder="Enter Email"
                  required
                  name="email"
                  value={emailInput}
                  onChange={handleEmailInput}
                  ref={emailInputRef}
                />
                }
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
              {/* <div className="form-links mt-2 mb-2 d-flex justify-content-center align-items-center"> */}
                {/* <div className="text-info fw-bold">
                  Dont have Website? <a href="#!">Build Instant Website</a>
                </div> */}
              {/* </div> */}
              {/* <div className="divider bg-secondary mb-2"></div> */}
              {/* <small className="mt-3">
                By logging in, you agree to our{" "}
                <a href="https://webweb.in/terms-conditions">Terms of Use</a> and
                to receive Webweb emails & updates and acknowledge that you read
                our <a href="https://webweb.in/privacy-policy">Privacy Policy</a>
              </small> */}
              <div className="form-button-group container login-form p-0 mt-2" style={{position: 'unset', minHeight: 'auto'}}>
                {hideSendOtpButton
                  ? <button className="btn btn-warning btn-block btn-lg" type="button" disabled>
                      <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                      Authorizing...
                  </button>
                  : <button className="btn btn-warning btn-block btn-lg" type="submit">
                  OTP ला विनंती करा
                </button>
                }
              </div>
            </form>
          </div>
        </div>
      }
      </>
      }
      {showVerifyOtpForm &&
        <div>
          <div className="appHeader no-border transparent position-absolute">
            <div className="left">
              <button type="button" className="btn btn-text-secondary btn-sm border-0 headerButton goBack" onClick={handleBackToLogin}><ion-icon name="chevron-back-outline"></ion-icon></button>
            </div>
            <div className="pageTitle"></div>
            <div className="right"></div>
          </div>
          <div className="login-form mt-5">
            <div className="section">
              <h1>OTP प्रविष्ट करा</h1>
              <h4>आम्ही तुमच्या फोनवर एक पडताळणी कोड पाठवला आहे</h4>
            </div>
            <div className="section mt-2 mb-5">
              <form onSubmit={HandleOtpValidation}>
                <div className="form-group boxed">
                  <div className="input-wrapper">
                    <input type="text" className="form-control verify-input" id="smscode" placeholder="••••••" maxlength={6} required name="otp"
                    />
                    {countDownTimer}
                  </div>
                </div>
                <div className="form-links mt-2 mb-2 d-flex justify-content-center align-items-center">
                  <div className="d-flex flex-column">
                    <span className="d-flex align-items-center justify-content-center">
                      <ion-icon name="stopwatch-outline"></ion-icon>&nbsp;
                    </span>
                    <button type="button" className="btn btn-outline-warning btn-sm rounded" onClick={reSendOtp} disabled={disableOtp}> OTP परत पाठवा </button>
                  </div>
                </div>
                <div className="form-button-group container login-form" style={{paddingRight: '16px', paddingLeft: '16px'}}>
                  <button className="btn btn-warning btn-block btn-lg" disabled={disableVerifyOtpButton}>
                    लॉग इन
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      }
      </div>
      </div>
      {
        appToastVisible &&
        <AppToast
          message={appToastMessage}
          show={true}
          background={appToastBackground}
          icon={appToastIcon}
          position={appToastPosition}
        />
      }
    </div>
  );
}

const mapStateToProps = ({ appToast, auth }) => {
  const {
    isVisible,
    toastMessage,
    background,
    icon,
    position
  } = appToast

  const {
    otpRequestSuccess,
    otpRequestError,
    hideSendOtpButton,
    showVerifyOtpForm,
    showRegistrationForm,
    otpValidateSuccess,
    otpValidateError
  } = auth

  return {
    appToastVisible: isVisible,
    appToastMessage: toastMessage,
    appToastBackground: background,
    appToastIcon: icon,
    appToastPosition: position,
    otpRequestSuccess,
    otpRequestError,
    hideSendOtpButton,
    showVerifyOtpForm,
    showRegistrationForm,
    otpValidateSuccess,
    otpValidateError
  }
}

const mapDispatchToProps = {
  showAppToast,
  hideAppToast,
  requestOtp,
  validateOtp,
  backToLogin,
  regitrationUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Authentication)