import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { Button, Table, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ReturnDDMMYYYYMarathi } from '../../features/auth/authSlice'

function GrahaneView({grahaneData}) {

    const [startDate, setStartDate] = useState(null)
    const [convertedDate, setConverted] = useState(null)

    const [LoadingData, setLoadingData] = useState(null);
  
    useEffect(() => {
     if(startDate) setConverted(ReturnDDMMYYYYMarathi(startDate.toString()))
    }, [startDate])
  
    useEffect(() => {
      setStartDate(new Date())
    }, [])

    const ReturnMonthAndYear = (dt) => {
      const date = new Date(dt);
      const month = date.getMonth();
      const year = date.getFullYear();
      return { month, year }
    }

    useEffect(() => {
      if(convertedDate && grahaneData) {
        const Mon = convertedDate.month;
        const Year = convertedDate.year;
        const newDailyMonthlyPanchang = grahaneData
        setLoadingData(newDailyMonthlyPanchang);
      }
    }, [convertedDate, grahaneData])
  
    const PrevMonth = (dt) => {
      const newDate = new Date(dt);
      const month = newDate.getMonth() - 1;
      if (month === -1) {
        newDate.setFullYear(newDate.getFullYear() - 1);
        newDate.setMonth(11); // January
      } else {
        newDate.setMonth(month);
      }
    setStartDate(newDate)
  }
  
    const NextMonth = (dt) => {
        const newDate = new Date(dt);
        const month = newDate.getMonth() + 1;
        if (month === 12) {
          newDate.setFullYear(newDate.getFullYear() + 1);
          newDate.setMonth(0); // January
        } else {
          newDate.setMonth(month);
        }
      setStartDate(newDate)
    }

  return (
    <div id="appCapsule">
            <div className="section mt-1">
                <h3 className='text-center'>ग्रहणे</h3>
                {/* <div className="my-2 d-flex justify-content-between align-items-center">
                    <Button variant="warning" size="sm" className="dateArrows" onClick={() => PrevMonth(startDate)}>
                        <i className="bi bi-caret-left-fill"></i>
                    </Button>
                    <div className='position-relative '>
                        <label htmlFor='panchangId'>
                        <p className="text-center m-0 fw-bold text-secondary">{convertedDate?.mahina} {convertedDate?.varsh}</p>
                        </label>
                        <DatePicker showMonthYearPicker showFullMonthYearPicker dateFormatCalendar='MMM yyyy' id="panchangId" className='KGdate PanchangMonthPicker' selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    <Button variant="warning" size="sm" className="dateArrows" onClick={() => NextMonth(startDate)}>
                        <i className="bi bi-caret-right-fill"></i>
                    </Button>
                </div> */}
            </div>
            <div className="section mt-2">
                <ul className="nav nav-tabs capsuled justify-content-evenly" role="tablist">
                    <li className="nav-item">
                        <a className="top-bar px-5 nav-link active" data-bs-toggle="tab" href="#grahane" role="tab"> ग्रहणे </a>
                    </li>
                    <li className="nav-item">
                        <a className="top-bar px-5 nav-link" data-bs-toggle="tab" href="#khulasa" role="tab"> खुलासा </a>
                    </li>
                </ul>
                <div className="tab-content mt-2">
                    <div className="tab-pane fade show active" id="grahane" role="tabpanel">
                        <div className="row">
                            <div className="col-12">
                              <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th className="text-center">नाव</th>
                                        <th className="text-center">तारीख</th>
                                        <th className="text-center">स्पर्श</th>
                                        <th className="text-center">मध्य</th>
                                        <th className="text-center">मोक्ष</th>
                                        <th className="text-center">भारतातून दिसणार</th>
                                    </tr>
                                </thead>
                                <tbody id="tdata">
                                  {
                                    LoadingData === null &&
                                    <tr ng-if="panchangData.length == 0" className="ng-scope">
                                        <td colspan="8" className="text-center">माहिती लोड करत आहे...</td>
                                    </tr>
                                  }
                                  {
                                    LoadingData && LoadingData.length === 0 &&
                                    <tr ng-if="panchangData.length == 0" className="ng-scope">
                                        <td colspan="8" className="text-center">माहिती उपलब्ध नाही</td>
                                    </tr>
                                  }
                                  {
                                    LoadingData && LoadingData.length > 0 &&
                                    <>
                                    {
                                      LoadingData?.map((item, keyK) => {
                                        return (
                                            <tr key={keyK}>
                                                <td className="text-center">{item.Category} {item.Type}</td>
                                                <td className="text-center">{item.Date}</td>
                                                <td className="text-center">{item.Sparsha}</td>
                                                <td className="text-center">{item.Madhya}</td>
                                                <td className="text-center">{item.Moksha}</td>
                                                <td className="text-center">
                                                  {
                                                    item.VisibleFromIndia === "0" ?
                                                      <i class="fa fa-times" aria-hidden="true"></i>
                                                    :
                                                    <></>
                                                  }
                                                  {
                                                    item.VisibleFromIndia === "1" ?
                                                      <i class="fa fa-check" aria-hidden="true"></i>
                                                    :
                                                    <></>
                                                  }
                                                </td>
                                            </tr>
                                        )
                                      })
                                    }
                                    </>
                                  }
                                </tbody>
                              </Table>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="khulasa" role="tabpanel">
                        <div className="col-12 col-md-6 mb-3">
                            <a href="https://webweb.ams3.cdn.digitaloceanspaces.com/Swami_Panchang_23/khulase.pdf" target="blank" ><button className="btn btn-warning btn-block">खुलासे</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

const mapStateToProps = ({ auth }) => {
    const {
      otpRequestSuccess,
      grahaneData
    } = auth
    return {
      otpRequestSuccess,
      grahaneData
    }
  }
  
  const mapDispatchToProps = {
    ReturnDDMMYYYYMarathi
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(GrahaneView)