import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout, setCity, ReturnDDMMYYYYMarathi } from '../../../features/auth/authSlice'
import { Form , Button, Offcanvas } from 'react-bootstrap';
import LogoOutline from '../../images/KGuruji-logo-outline.png'
import AppToast from '../../Elements/Notification/AppToast';

const Header = ({ logout, userData, setCity, citySelect, allCities }) => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [selectValue, setSelectCityValue] = useState('पुणे');

  const handleUserProfile = () => {
    navigate('/userProfile')
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDropdownCityChange = (e) => {
    const selectedCity = allCities.filter ((item) => item.city === e.target.value)
    setCity({
        'City': selectedCity[0]
    })
    localStorage.setItem ('selectedCity', JSON.stringify (selectedCity[0]))
    // setSelectCityValue(e.target.value);
};
    useEffect (() => {
        if (localStorage.getItem('selectedCity')){
            const getCity = JSON.parse(localStorage.getItem('selectedCity'))
            setCity({
                'City': getCity
            })
        }
    }, [])

    const handleLogout = (e) => {
        localStorage.clear();
        window.location.href = "/"
    }

    const navigateTo = (URL) => {
        navigate(URL)
        document.getElementById('modalCloseButton').click()
    }

  return (
    <div>
        <AppToast></AppToast>
      <div className="appHeader bg-warning scrolled">
        <div className="left">
            <a 
                href="#"
                className="headerButton"
                data-bs-toggle="modal"
                data-bs-target="#sidebarPanel"
                onClick={handleShow}
            >
                <ion-icon className="headerIcons" name="menu-outline"></ion-icon>
            </a>
        </div>
        <div className="pageTitle d-flex flex-column align-items-center justify-content-center m-0">
            <p className="fw-bold text-center m-0">श्री स्वामी समर्थ पंचांग</p>
            <p className="fw-bold text-center m-0">{ReturnDDMMYYYYMarathi(new Date()).varsh}</p>
        </div>
        <div className="right">
            <div className='flex-column p-1 headerButton toggle-searchbox'>
                <ion-icon name="locate"></ion-icon>
                <h5 className='m-0 text-white'>
                    <Form.Select className='selectLocation' onChange={handleDropdownCityChange}>
                        {allCities.map((item) => {
                            return <option value={item.city} selected={item.city === citySelect.city}>{item.shahar}</option>
                        })}
                    </Form.Select>
                </h5>
            </div>
        </div>
      </div>

      {/* <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header className="profileBox bg-warning" closeButton>
          <Offcanvas.Title>Offcanvas
            <div>
                <div className="in">
                    <strong>सौरभ कर्वे</strong>
                    <div className="text-muted">
                        <ion-icon name="location"></ion-icon>
                        पनवेल
                    </div>
                </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas> */}

      <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-body p-0">
                    <div className="profileBox bg-warning">
                        <div className="in">
                            <strong>{userData?.Name}</strong>
                            <div className="text-muted">
                                <ion-icon name="location-outline"></ion-icon>
                                {citySelect.shahar}
                            </div>
                        </div>
                        <a href="#" id='modalCloseButton' className="close-sidebar-button" data-bs-dismiss="modal">
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </div>

                    <ul className="listview flush transparent no-line image-listview">
                        <li>
                            <div onClick={() => navigateTo('/tipani')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="create"></ion-icon>
                                </div> */}
                                <div className="in">
                                  टिपणी
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/panchang')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="create"></ion-icon>
                                </div> */}
                                <div className="in">
                                  पंचांग
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/grahaBadal')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  ग्रहराशी बदल 
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/muhurta')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="layers-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  मुहूर्त 
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/shubhadivas')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  शुभदिवस 
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/sankashtiChandroday')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  संकष्टी चंद्रोदय 
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/grahane')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  ग्रहणे/खुलासा
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/agniAhuti')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  अग्नी आहुती 
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/lopDarshan')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  लोपदर्शन
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/amrutsidhhiYog')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  अमृतसिद्धी योग
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/gurupushpamrut')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  गुरुपुष्यामृत योग
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/dhanishthaPanchak')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  धनिष्ठा पंचक
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/parjanyaNakshatra')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  पर्जन्य नक्षत्र
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/dinvishesh')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  दिनविशेष
                                </div>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => navigateTo('/tithi')} className="item">
                                {/* <div className="icon-box bg-warning">
                                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                                </div> */}
                                <div className="in">
                                  श्राद्धतिथी
                                </div>
                            </div>
                        </li>
                        {/* <li>
                            <div className="item">
                                <div className="icon-box bg-warning">
                                    <ion-icon name="moon-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    <div>Dark Mode</div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input dark-mode-switch" type="checkbox"
                                            id="darkmodesidebar" />
                                        <label className="form-check-label" for="darkmodesidebar"></label>
                                    </div>
                                </div>
                            </div>
                        </li> */}
                    </ul>
                </div>
                <div className="appBottomMenu bg-warning text-light">
                    <Button variant="warning" href="tel:+918898683883" className="item">
                        <div className="col">
                            <ion-icon name="call-outline"></ion-icon>
                            <strong>कॉल</strong>
                        </div>
                    </Button>
                    <Button variant="warning" onClick={() => navigateTo('/appinfo')} className="item">
                        <div className="col">
                            <ion-icon name="information-circle-outline"></ion-icon>
                            <strong>माहिती</strong>
                        </div>
                    </Button>
                    <Button  variant="warning" className="item" onClick={handleLogout}>
                        <div className="col">
                            <ion-icon className='m-0' name="log-out-outline"></ion-icon>
                            <strong>लॉग आउट</strong>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ( { auth } ) => {
    const { userData, citySelect, allCities } = auth

    return {
        userData,
        citySelect,
        allCities
    }
}

const mapDispatchToProps = {
  logout,
  setCity
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)
