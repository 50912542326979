import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import axios from '../../AxiosConfig'
import { Button, Table, Form, Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ReturnDDMMYYYYMarathi } from '../../features/auth/authSlice'

function TipaniView({panchangData, userData, StartEnable, EndEnable}) {

  const [startDate, setStartDate] = useState(null)
  const [convertedDate, setConverted] = useState(null)
  const [currentTipani, setCurrentTipani] = useState(null)
  const [textPara, setTextPara] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [customModal, setCustomModal] = useState(null)

  useEffect(() => {
    if (userData) {
      axios.get(`/application/getUserData/${userData.ID}`).then
      (res => {
        if (res.data.statusMessage === 'Success' && res.data.payLoad.Success.ShowModal === "1") {
          setShow(true)
          setCustomModal(res.data.payLoad.Success.CustomModalText)
        }
      })
    }
  }, [userData])

  useEffect(() => {
   if(startDate) setConverted(ReturnDDMMYYYYMarathi(startDate.toString()))
  }, [startDate])

  useEffect(() => {
    setStartDate(new Date())
  }, [])

  const PrevMonth = (dt) => {
    const newDate = new Date(dt);
    const date = newDate.getDate() - 1;
    if (date === 0) {
      const month = newDate.getMonth() - 1;
      if (month === -1) {
        newDate.setFullYear(newDate.getFullYear() - 1);
        newDate.setMonth(11); // December
      } else {
        newDate.setMonth(month);
      }
      newDate.setDate(new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate());
    } else {
      newDate.setDate(date);
    }
    setStartDate(newDate);
  };
  
  const NextMonth = (dt) => {
    const newDate = new Date(dt);
    const date = newDate.getDate() + 1;
    if (date > new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate()) {
      const month = newDate.getMonth() + 1;
      if (month === 12) {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setMonth(0); // January
      } else {
        newDate.setMonth(month);
      }
      newDate.setDate(1);
    } else {
      newDate.setDate(date);
    }
    setStartDate(newDate);
    console.log(newDate.getMonth)
  };

  const getCombineDate = (date, mon, year) => {
    const newDate = `${date < 10 ? 0 : ''}${date}/${mon < 10 ? 0 : ''}${mon}/${year}`
    return newDate;
  }
  
  useEffect(() => {
    if(convertedDate) {
      console.log(convertedDate.date, convertedDate.month, convertedDate.year)
      const combineDate = getCombineDate(convertedDate.date, convertedDate.month + 1, convertedDate.year)
      const filterData = panchangData.filter((item) => item.cdt === combineDate)
      console.log(combineDate, filterData)
      if(filterData.length > 0) {
        setCurrentTipani(filterData[0]);
      }

      const formData = new FormData();
      formData.append('cVersion', 10008);
      axios.post(`/application/checkAppUpdate`, formData).then
      (res => {
        if (res.data.statusMessage === 'Success') {
          if(res.data.payLoad.Update === 1) {
            window.location.href = "https://play.google.com/store/apps/details?id=in.swamipanchang.app";
          } else {

              
          }
        }
      })
      axios.get(`/application/notes/${convertedDate.year}-${convertedDate.month + 1}-${convertedDate.date}/${userData.ID}/1`).then(res => {
        if (res.data.statusMessage === 'Success') {
          if(res.data.payLoad.Success[0]) {
            setTextPara(res.data.payLoad.Success[0]?.Notes)
          } else {
            setTextPara('');
          }
        }
      })
    }
  }, [convertedDate, userData])

  useEffect(() => {
    if(textPara) {
      const formData = new FormData();
      formData.append('Notes', textPara);
      formData.append('UserID', userData.ID);
      axios.post(`/application/notes/${convertedDate.year}-${convertedDate.month + 1}-${convertedDate.date}/${userData.ID}/1`, formData).then(res => {
        if (res.data.statusMessage === 'Success') {
          console.log('done')
        }
      })
    }
  }, [textPara, userData])

  return (
    <div id="appCapsule">
      <div className="section mt-1">
        <h3 className='text-center'>टिपणी</h3>
        <div className="my-2 d-flex justify-content-between align-items-center">
          <Button variant="warning" size="sm" disabled={convertedDate?.year === StartEnable && convertedDate?.month === 0 && convertedDate?.date === 1} className="dateArrows" onClick={() => PrevMonth(startDate)}>
            <i className="bi bi-caret-left-fill"></i>
          </Button>
          <div className='position-relative '>
            <label htmlFor='panchangId'>
              <p className="text-center m-0 fw-bold text-secondary">{convertedDate?.taarikh} {convertedDate?.mahina} {convertedDate?.varsh}</p>
            </label>
            <DatePicker showFullMonthYearPicker minDate={new Date(`${StartEnable}/01/01`)}  maxDate={new Date(`${EndEnable}/12/31`)} id="panchangId" className='KGdate PanchangMonthPicker' selected={startDate} onChange={(date) => setStartDate(date)} />
          </div> 
          <Button variant="warning" size="sm" disabled={convertedDate?.year === EndEnable && convertedDate?.month === 11 && convertedDate?.date === 31} className="dateArrows" onClick={() => NextMonth(startDate)}>
            <i className="bi bi-caret-right-fill"></i>
          </Button>
        </div>
      </div>
      <div className='section mt-2'>
        <p className='para'>
          {currentTipani?.Var} {currentTipani?.PStr} {currentTipani?.TStr} {currentTipani?.NStr} {currentTipani?.YStr} {currentTipani?.KStr} {currentTipani?.RStr} {currentTipani?.DStr} {currentTipani?.Agni} {currentTipani?.Ahuti} {currentTipani?.Shradha} <strong>{currentTipani?.shubh}{currentTipani?.DinVishesh}</strong>
        </p>
      </div>
      <div className="section mt-2 p-0">
          <div>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control value={textPara} onChange={(e) =>  setTextPara(e.target.value)} className='tipaniNotes' as="textarea" rows={13} />
              </Form.Group>
            </Form>
          </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{__html: customModal}}>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const {
    otpRequestSuccess,
    panchangData,
    userData,
    StartEnable,
    EndEnable
  } = auth
  return {
    otpRequestSuccess,
    panchangData,
    userData,
    StartEnable,
    EndEnable
  }
}

const mapDispatchToProps = {
  ReturnDDMMYYYYMarathi
}

export default connect(mapStateToProps, mapDispatchToProps)(TipaniView)
