import React, { useState , useEffect } from 'react';
import { Button , Table } from 'react-bootstrap';
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReturnDDMMYYYYMarathi } from '../../features/auth/authSlice'

function AgniAhutiView({ahutiData, agniData, StartEnable, EndEnable}) {

  const [startDate, setStartDate] = useState(null)
  const [convertedDate, setConverted] = useState(null)

  const [LoadingData, setLoadingData] = useState(null);
  const [LoadingData2, setLoadingData2] = useState(null);

  useEffect(() => {
   if(startDate) setConverted(ReturnDDMMYYYYMarathi(startDate.toString()))
  }, [startDate])

  useEffect(() => {
    setStartDate(new Date())
  }, [])

  const ReturnMonthAndYear = (dt) => {
    const date = new Date(dt);
    const month = date.getMonth();
    const year = date.getFullYear();
    return { month, year }
  }

  useEffect(() => {
    if(convertedDate && ahutiData) {
      const Mon = convertedDate.mahinaAnk;
      const Year = convertedDate.varsh;
      console.log(Mon, Year);
      const newDailyMonthlyPanchang = agniData.filter((item) => item.cmth === Mon && item.cyr === Year)
      setLoadingData(newDailyMonthlyPanchang);
      const newDailyMonthlyPanchang2 = ahutiData.filter((item) => item.cmth === Mon && item.cyr === Year)
      setLoadingData2(newDailyMonthlyPanchang2);
    }
  }, [convertedDate, ahutiData, agniData])

  const PrevMonth = (dt) => {
    const newDate = new Date(dt);
    const month = newDate.getMonth() - 1;
    if (month === -1) {
      newDate.setFullYear(newDate.getFullYear() - 1);
      newDate.setMonth(11); // January
    } else {
      newDate.setMonth(month);
    }
  setStartDate(newDate)
}

  const NextMonth = (dt) => {
      const newDate = new Date(dt);
      const month = newDate.getMonth() + 1;
      if (month === 12) {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setMonth(0); // January
      } else {
        newDate.setMonth(month);
      }
    setStartDate(newDate)
  }

  return (
    <div id="appCapsule">
            <div className="section mt-2 p-0">
                <div className="section full">
                  <h3 className='text-center'>अग्नी आहुती</h3>
                  <div className="d-flex justify-content-between align-items-center mb-2 px-2">
                      <Button variant="warning" size="sm" disabled={convertedDate?.year === StartEnable && convertedDate?.month === 0} className="dateArrows" onClick={() => PrevMonth(startDate)}>
                          <i className="bi bi-caret-left-fill"></i>
                      </Button>
                      <div className='position-relative '>
                          <label htmlFor='panchangId'>
                          <p className="text-center m-0 fw-bold text-secondary">{convertedDate?.mahina} {convertedDate?.varsh}</p>
                          </label>
                          <DatePicker minDate={new Date(`${StartEnable}/01/01`)}  maxDate={new Date(`${EndEnable}/12/31`)} showMonthYearPicker showFullMonthYearPicker dateFormatCalendar='MMM yyyy' id="panchangId" className='KGdate PanchangMonthPicker' selected={startDate} onChange={(date) => setStartDate(date)} />
                      </div>
                      <Button variant="warning" size="sm" disabled={convertedDate?.year === EndEnable && convertedDate?.month === 11} className="dateArrows" onClick={() => NextMonth(startDate)}>
                          <i className="bi bi-caret-right-fill"></i>
                      </Button>
                  </div>  
                  <div className="section mt-2">
                      <ul className="nav nav-tabs capsuled justify-content-evenly" role="tablist">
                          <li className="nav-item">
                              <a className="top-bar px-5 nav-link active" data-bs-toggle="tab" href="#agni" role="tab"> अग्नी </a>
                          </li>
                          <li className="nav-item">
                              <a className="top-bar px-5 nav-link" data-bs-toggle="tab" href="#ahuti" role="tab"> आहुती </a>
                          </li>
                      </ul>

                      <div className="tab-content mt-2">
                          <div className="tab-pane fade show active" id="agni" role="tabpanel">
                            <Table striped bordered hover responsive>
                              <thead>
                                <tr>
                                  <th className="text-center">तारीख</th>
                                  <th className="text-center">मास</th>
                                  <th className="text-center">तिथी</th>
                                  <th className="text-center">अग्नी</th>
                                </tr>
                              </thead>
                              <tbody id="tdata">
                                {
                                  LoadingData === null &&
                                  <tr ng-if="panchangData.length == 0" className="ng-scope">
                                      <td colspan="8" className="text-center">माहिती लोड करत आहे...</td>
                                  </tr>
                                }
                                {
                                  LoadingData && LoadingData.length === 0 &&
                                  <tr ng-if="panchangData.length == 0" className="ng-scope">
                                      <td colspan="8" className="text-center">माहिती उपलब्ध नाही</td>
                                  </tr>
                                }
                                {
                                  LoadingData && LoadingData.length > 0 &&
                                  <>
                                  {
                                    LoadingData?.map((item, keyK) => {
                                      return (
                                          <tr key={keyK}>
                                            <td className="text-center">{item.cdt}</td>
                                            <td className="text-center">{item.mahina} {item.shake}</td>
                                            <td className="text-center">{item.paksh}</td>
                                            <td className="text-center">{item.time}</td>
                                          </tr>
                                      )
                                    })
                                  }
                                  </>
                                }
                              </tbody>
                            </Table>
                          </div>
                          <div className="tab-pane fade" id="ahuti" role="tabpanel">
                            <Table striped bordered hover responsive>
                              <thead>
                                <tr>
                                  <th className="text-center">तारीख</th>
                                  <th className="text-center">मास</th>
                                  <th className="text-center">तिथी</th>
                                  <th className="text-center">आहुती</th>
                                </tr>
                              </thead>
                              <tbody id="tdata">
                                {
                                  LoadingData2 === null &&
                                  <tr ng-if="panchangData.length == 0" className="ng-scope">
                                      <td colspan="8" className="text-center">माहिती लोड करत आहे...</td>
                                  </tr>
                                }
                                {
                                  LoadingData2 && LoadingData2.length === 0 &&
                                  <tr ng-if="panchangData.length == 0" className="ng-scope">
                                      <td colspan="8" className="text-center">माहिती उपलब्ध नाही</td>
                                  </tr>
                                }
                                {
                                  LoadingData2 && LoadingData2.length > 0 &&
                                  <>
                                  {
                                    LoadingData2?.map((item, keyK) => {
                                      return (
                                          <tr key={keyK}>
                                            <td className="text-center">{item.cdt}</td>
                                            <td className="text-center">{item.mahina} {item.shake}</td>
                                            <td className="text-center">{item.paksh}</td>
                                            <td className="text-center">{item.time}</td>
                                          </tr>
                                      )
                                    })
                                  }
                                  </>
                                }
                              </tbody>
                            </Table>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
  )
}

    const mapStateToProps = ({ auth }) => {
        const {
        otpRequestSuccess,
        ahutiData,
        agniData,
        StartEnable,
        EndEnable
        } = auth
        return {
        otpRequestSuccess,
        ahutiData,
        agniData,
        StartEnable,
        EndEnable
        }
    }
    
    const mapDispatchToProps = {
        ReturnDDMMYYYYMarathi
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(AgniAhutiView)