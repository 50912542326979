import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { Button, Table, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ReturnDDMMYYYYMarathi } from '../../features/auth/authSlice'

function MuhurtaView({muhurtaData, lagnaData, upnayanData, citySelect, StartEnable, EndEnable}) {

    const [startDate, setStartDate] = useState(null)
    const [convertedDate, setConverted] = useState(null)
    const [selectedMuhurta, setSelectedMuhurta] = useState('vivah')
    const [LoadingData, setLoadingData] = useState(null);

    useEffect(() => {
     if(startDate) setConverted(ReturnDDMMYYYYMarathi(startDate.toString()))
    }, [startDate])
  
    useEffect(() => {
      setStartDate(new Date())
    }, [])

    const ReturnMonthAndYear = (dt) => {
      const date = new Date(dt);
      const month = date.getMonth();
      const year = date.getFullYear();
      console.log(month, year)
      return { month, year }
    }

    useEffect(() => {
      if(convertedDate && muhurtaData && selectedMuhurta) {
        const Mon = convertedDate.month;
        const Year = convertedDate.year;
        let newDailyMonthlyPanchang;
        if(selectedMuhurta === 'vivah') {
          console.log(Mon)
          newDailyMonthlyPanchang = lagnaData.filter((item) => ReturnMonthAndYear(item.Date).month === Mon && ReturnMonthAndYear(item.Date).year === Year)
        } else if(selectedMuhurta === 'upnayan') {
          console.log(Mon)
          newDailyMonthlyPanchang = upnayanData.filter((item) => ReturnMonthAndYear(item.Date).month === Mon && ReturnMonthAndYear(item.Date).year === Year)
        } else {
          newDailyMonthlyPanchang = muhurtaData.Success.filter((item) => ReturnMonthAndYear(item.Date).month === Mon && ReturnMonthAndYear(item.Date).year === Year)
        }
        console.log(lagnaData)
        console.log(newDailyMonthlyPanchang)
        const sortedData = newDailyMonthlyPanchang.sort((a, b) => {
          const dateA = new Date(a.Date);
          const dateB = new Date(b.Date);
          return dateA - dateB;
        });
        setLoadingData(sortedData);
      }
    }, [convertedDate, muhurtaData, lagnaData, upnayanData, selectedMuhurta, citySelect])
  
    const PrevMonth = (dt) => {
      const newDate = new Date(dt);
      const month = newDate.getMonth() - 1;
      if (month === -1) {
        newDate.setFullYear(newDate.getFullYear() - 1);
        newDate.setMonth(11); // January
      } else {
        newDate.setMonth(month);
      }
    setStartDate(newDate)
    }
  
    const NextMonth = (dt) => {
        const newDate = new Date(dt);
        const month = newDate.getMonth() + 1;
        if (month === 12) {
          newDate.setFullYear(newDate.getFullYear() + 1);
          newDate.setMonth(0); // January
        } else {
          newDate.setMonth(month);
        }
      setStartDate(newDate)
    }

  return (
    <div id="appCapsule">
            <div className="section mt-2">
                <h3 className='text-center'>मुहूर्त</h3>
                <div>
                    <div>
                        <Form.Select onChange={(e) => setSelectedMuhurta(e.target.value)}>
                            <option value="vivah">विवाह</option>
                            <option value="upnayan">उपनयन</option>
                            <option value="javal">जावळ</option>
                            <option value="vastu">वास्तु</option>
                            <option value="vaahan">वाहन खरेदी</option>
                            <option value="vyapar">व्यापार</option>
                            <option value="namakaran">नामकरण</option>
                            <option value="bhumipujan">भूमिपूजन</option>
                            <option value="sakharpuda">साखरपुडा</option>
                            <option value="dohalejewan">डोहाळेजेवण</option>
                            <option value="vihirkupnalika">विहिरकुपनलिका</option>
                            <option value="upanayanGuruasta">गुरु अस्तातील उपनयन</option>
                            <option value="chaturmasatilVivah">चातुर्मासातील विवाह मुहूर्त</option>
                            <option value="guruastatilMuhurta">गुरु अस्तातील मुहूर्त</option>
                            <option value="upanayanShukraasta">शुक्र अस्तातील उपनयन</option>
                            <option value="shukraastatilMuhurta">शुक्र अस्तातील मुहूर्त</option>
                            <option value="dakshinayanatilUpanayan">दक्षिणायनातील उपनयन</option>
                        </Form.Select>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                        <Button variant="warning" size="sm" disabled={convertedDate?.year === StartEnable && convertedDate?.month === 0} className="dateArrows" onClick={() => PrevMonth(startDate)}>
                            <i className="bi bi-caret-left-fill"></i>
                        </Button>
                        <div className='position-relative '>
                            <label htmlFor='panchangId'>
                            <p className="text-center m-0 fw-bold text-secondary">{convertedDate?.mahina} {convertedDate?.varsh}</p>
                            </label>
                            <DatePicker showMonthYearPicker showFullMonthYearPicker dateFormatCalendar='MMM yyyy' minDate={new Date(`${StartEnable}/01/01`)}  maxDate={new Date(`${EndEnable}/12/31`)} id="panchangId" className='KGdate PanchangMonthPicker' selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                        <Button variant="warning" size="sm" disabled={convertedDate?.year === EndEnable && convertedDate?.month === 11} className="dateArrows" onClick={() => NextMonth(startDate)}>
                            <i className="bi bi-caret-right-fill"></i>
                        </Button>
                    </div>
                </div>
            </div>
            <div className='section mt-2'>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="text-center">तारीख</th>
                    <th className="text-center">वेळ</th>
                  </tr>
                </thead>
                <tbody id="tdata">
                  {
                    LoadingData === null &&
                    <tr ng-if="panchangData.length == 0" className="ng-scope">
                        <td colspan="2" className="text-center">माहिती लोड करत आहे...</td>
                    </tr>
                  }
                  {
                    LoadingData && LoadingData.length === 0 &&
                    <tr ng-if="panchangData.length == 0" className="ng-scope">
                        <td colspan="2" className="text-center">माहिती उपलब्ध नाही</td>
                    </tr>
                  }
                  {
                    LoadingData && LoadingData.length > 0 &&
                    <>
                    {
                      LoadingData?.map((item, keyK) => {
                        return (
                          <tr key={keyK}>
                            <td className="text-center">{item.Date}</td>
                            {
                              selectedMuhurta === 'vivah' ?
                              <td className="text-center">
                                {
                                  item[citySelect.city]
                                }</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'upnayan' ?
                              <td className="text-center">
                                {
                                  item[citySelect.city]
                                }</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'javal' ?
                              <td className="text-center">{item.javal}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'vastu' ?
                              <td className="text-center">{item.vastu}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'vaahan' ?
                              <td className="text-center">{item.vaahan}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'vyapar' ?
                              <td className="text-center">{item.vyapar}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'namakaran' ?
                              <td className="text-center">{item.namakaran}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'bhumipujan' ?
                              <td className="text-center">{item.bhumipujan}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'sakharpuda' ?
                              <td className="text-center">{item.sakharpuda}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'dohalejewan' ?
                              <td className="text-center">{item.dohalejewan}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'vihirkupnalika' ?
                              <td className="text-center">{item.vihirkupnalika}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'upanayanGuruasta' ?
                              <td className="text-center">{item.upanayanGuruasta}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'chaturmasatilVivah' ?
                              <td className="text-center">{item.chaturmasatilVivah}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'guruastatilMuhurta' ?
                              <td className="text-center">{item.guruastatilMuhurta}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'upanayanShukraasta' ?
                              <td className="text-center">{item.upanayanShukraasta}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'shukraastatilMuhurta' ?
                              <td className="text-center">{item.shukraastatilMuhurta}</td>
                              :
                              <></>
                            }
                            {
                              selectedMuhurta === 'dakshinayanatilUpanayan' ?
                              <td className="text-center">{item.dakshinayanatilUpanayan}</td>
                              :
                              <></>
                            }
                          </tr>
                        )
                      })
                    }
                    </>
                  }
                </tbody>
              </Table>
            </div>
        </div>
  )
}

const mapStateToProps = ({ auth }) => {
    const {
      otpRequestSuccess,
      muhurtaData,
      lagnaData,
      upnayanData,
      citySelect,
      StartEnable,
      EndEnable
    } = auth
    return {
      otpRequestSuccess,
      muhurtaData,
      lagnaData,
      upnayanData,
      citySelect,
      StartEnable,
      EndEnable
    }
  }
  
  const mapDispatchToProps = {
    ReturnDDMMYYYYMarathi
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(MuhurtaView)