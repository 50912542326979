import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from "react-redux"
import { Button, Table, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ReturnDDMMYYYYMarathi } from '../../features/auth/authSlice'

function ParjanyaNakshatraView({parjanyaNakshtraData}) {

    const [startDate, setStartDate] = useState(null)
    const [convertedDate, setConverted] = useState(null)

    const [LoadingData, setLoadingData] = useState(null)
  
    useEffect(() => {
     if(startDate) setConverted(ReturnDDMMYYYYMarathi(startDate.toString()))
    }, [startDate])
  
    useEffect(() => {
      setStartDate(new Date())
    }, [])

    const ReturnMonthAndYear = (dt) => {
      const date = new Date(dt);
      const month = date.getMonth();
      const year = date.getFullYear();
      return { month, year }
    }

    useEffect(() => {
      if(convertedDate && parjanyaNakshtraData) {
        const Mon = convertedDate.month;
        const Year = convertedDate.year;
        const newDailyMonthlyPanchang = parjanyaNakshtraData
        setLoadingData(newDailyMonthlyPanchang);
      }
    }, [convertedDate, parjanyaNakshtraData])
  
    const PrevMonth = (dt) => {
      const newDate = new Date(dt);
      const month = newDate.getMonth() - 1;
      if (month === -1) {
        newDate.setFullYear(newDate.getFullYear() - 1);
        newDate.setMonth(11); // January
      } else {
        newDate.setMonth(month);
      }
    setStartDate(newDate)
  }
  
    const NextMonth = (dt) => {
        const newDate = new Date(dt);
        const month = newDate.getMonth() + 1;
        if (month === 12) {
          newDate.setFullYear(newDate.getFullYear() + 1);
          newDate.setMonth(0); // January
        } else {
          newDate.setMonth(month);
        }
      setStartDate(newDate)
    }

  return (
    <div id="appCapsule">
        <div className="section mt-1">
            <h3 className='text-center'>पर्जन्य नक्षत्र</h3>
            {/* <div className="my-2 d-flex justify-content-between align-items-center">
                <Button variant="warning" size="sm" className="dateArrows" onClick={() => PrevMonth(startDate)}>
                    <i className="bi bi-caret-left-fill"></i>
                </Button>
                <div className='position-relative '>
                    <label htmlFor='panchangId'>
                    <p className="text-center m-0 fw-bold text-secondary">{convertedDate?.mahina} {convertedDate?.varsh}</p>
                    </label>
                    <DatePicker showMonthYearPicker showFullMonthYearPicker dateFormatCalendar='MMM yyyy' id="panchangId" className='KGdate PanchangMonthPicker' selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <Button variant="warning" size="sm" className="dateArrows" onClick={() => NextMonth(startDate)}>
                    <i className="bi bi-caret-right-fill"></i>
                </Button>
            </div> */}
        </div>
        <div className="section">
          <Table striped bordered hover responsive>
            <thead>
                <tr>
                  <th className="text-center">तारीख</th>
                  <th className="text-center">दिवस</th>
                </tr>
            </thead>
            <tbody id="tdata">
              {
                LoadingData === null &&
                <tr ng-if="panchangData.length == 0" className="ng-scope">
                    <td colspan="8" className="text-center">माहिती लोड करत आहे...</td>
                </tr>
              }
              {
                LoadingData && LoadingData.length === 0 &&
                <tr ng-if="panchangData.length == 0" className="ng-scope">
                    <td colspan="8" className="text-center">माहिती उपलब्ध नाही</td>
                </tr>
              }
              {
                LoadingData && LoadingData.length > 0 &&
                <>
                {
                  LoadingData?.map((item, keyK) => {
                    return (
                        <tr key={keyK}>
                            <td className="text-center">{item.Date}</td>
                            <td className="text-center">{item.Maryada}</td>
                        </tr>
                    )
                  })
                }
                </>
              }
            </tbody>
          </Table>
        </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
    const {
      otpRequestSuccess,
      parjanyaNakshtraData
    } = auth
    return {
      otpRequestSuccess,
      parjanyaNakshtraData
    }
  }
  
  const mapDispatchToProps = {
    ReturnDDMMYYYYMarathi
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ParjanyaNakshatraView)