import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from "react-redux"
import { Button, Table, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ReturnDDMMYYYYMarathi } from '../../features/auth/authSlice'

function PanchangView({ dailyPanchang, StartEnable, EndEnable }) {
  
  const [startDate, setStartDate] = useState(null)
  const [convertedDate, setConverted] = useState(null);

  const [LoadingData, setLoadingData] = useState(null);

  useEffect(() => {
   if(startDate) setConverted(ReturnDDMMYYYYMarathi(startDate.toString()))
  }, [startDate])

  useEffect(() => {
    setStartDate(new Date())
  }, [])

  useEffect(() => {
    if(convertedDate && dailyPanchang) {
      const newDailyMonthlyPanchang = dailyPanchang.Success.filter((item) => item.cmth === convertedDate.mahinaAnk && item.cyr === convertedDate.varsh)
      setLoadingData(newDailyMonthlyPanchang);
    }
  }, [convertedDate, dailyPanchang])

  const PrevMonth = (dt) => {
    const newDate = new Date(dt);
    const month = newDate.getMonth() - 1;
    if (month === -1) {
      newDate.setFullYear(newDate.getFullYear() - 1);
      newDate.setMonth(11); // January
    } else {
      newDate.setMonth(month);
    }
  setStartDate(newDate)
}

  const NextMonth = (dt) => {
      const newDate = new Date(dt);
      const month = newDate.getMonth() + 1;
      if (month === 12) {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setMonth(0); // January
      } else {
        newDate.setMonth(month);
      }
    setStartDate(newDate)
  }

  return (
    <div id="appCapsule">
      <div className="section mt-1">
        <h3 className="text-center">पंचांग</h3>
        <div className="my-2 d-flex justify-content-between align-items-center">
          <Button variant="warning" size="sm" disabled={convertedDate?.year === StartEnable && convertedDate?.month === 0} className="dateArrows" onClick={() => PrevMonth(startDate)}>
            <i className="bi bi-caret-left-fill"></i>
          </Button>
          <div className='position-relative '>
            <label htmlFor='panchangId'>
              <p className="text-center m-0 fw-bold text-secondary">{convertedDate?.mahina} {convertedDate?.varsh}</p>
            </label>
            <DatePicker showMonthYearPicker showFullMonthYearPicker dateFormatCalendar='MMM yyyy' minDate={new Date(`${StartEnable}/01/01`)}  maxDate={new Date(`${EndEnable}/12/31`)} id="panchangId" className='KGdate PanchangMonthPicker' selected={startDate} onChange={(date) => setStartDate(date)} />
          </div>
          <Button variant="warning" size="sm" disabled={convertedDate?.year === EndEnable && convertedDate?.month === 11} className="dateArrows" onClick={() => NextMonth(startDate)}>
            <i className="bi bi-caret-right-fill"></i>
          </Button>
        </div>
      </div>
      <div className="section mt-2">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
                <th className="text-center">तारीख</th>
                <th className="text-center">वार</th>
                <th className="text-center">शके</th>
                <th className="text-center">महिना</th>
                <th className="text-center">पक्ष</th>
                <th className="text-center">तिथी</th>
                <th className="text-center">क.मि.</th>
                <th className="text-center">नक्षत्र</th>
                <th className="text-center">क.मि.</th>
                <th className="text-center">योग</th>
                <th className="text-center">क.मि.</th>
                <th className="text-center">दिवा</th>
                <th className="text-center">करण</th>
                <th className="text-center">रात्रौ</th>
                <th className="text-center">करण</th>
                <th className="text-center">रवी उदय</th>
                <th className="text-center">रवी अस्त</th>
                <th className="text-center">चंद्ररास</th>
                <th className="text-center">क.मि.</th>
                <th className="text-center">शहर</th>
            </tr>
          </thead>
          <tbody id="tdata">
            {
              LoadingData === null &&
              <tr ng-if="panchangData.length == 0" className="ng-scope">
                  <td colspan="8" className="text-center">माहिती लोड करत आहे...</td>
              </tr>
            }
            {
              LoadingData && LoadingData.length === 0 &&
              <tr ng-if="panchangData.length == 0" className="ng-scope">
                  <td colspan="8" className="text-center">माहिती उपलब्ध नाही</td>
              </tr>
            }
            {
              LoadingData && LoadingData.length > 0 &&
              <>
              {
                LoadingData?.map((item, keyK) => {
                  return (
                      <tr key={keyK}>
                          <td className="text-center">{item.cdt}</td>
                          <td className="text-center">{item.vardes}</td>
                          <td className="text-center">{item.Shake}</td>
                          <td className="text-center">{item.Month}</td>
                          <td className="text-center">{item.Paksha}</td>
                          <td className="text-center">{item.t1des}</td>
                          <td className="text-center">{item.t1edt}</td>
                          <td className="text-center">{item.n1des}</td>
                          <td className="text-center">{item.n1edt}</td>
                          <td className="text-center">{item.y1des}</td>
                          <td className="text-center">{item.y1edt}</td>
                          <td className="text-center">{item.k1des}</td>
                          <td className="text-center">{item.k1edt}</td>
                          <td className="text-center">{item.k2des}</td>
                          <td className="text-center">{item.k2edt}</td>
                          <td className="text-center">{item.sredt}</td>
                          <td className="text-center">{item.ssedt}</td>
                          <td className="text-center">{item.rsdes}</td>
                          <td className="text-center">{item.rsedt}</td>
                          <td className="text-center">{item.city_name}</td>
                      </tr>
                  )
                })
              }
              </>
            }
          </tbody>
        </Table>
      </div>
    </div>
  )
}


const mapStateToProps = ({ auth }) => {
  const {
    otpRequestSuccess,
    dailyPanchang,
    StartEnable,
    EndEnable
  } = auth
  return {
    otpRequestSuccess,
    dailyPanchang,
    StartEnable,
    EndEnable
  }
}

const mapDispatchToProps = {
  ReturnDDMMYYYYMarathi
}

export default connect(mapStateToProps, mapDispatchToProps)(PanchangView)
